import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxPivotGridFieldChooser from 'devextreme/ui/pivot_grid_field_chooser';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxoHeaderFilterModule, DxoSearchModule, DxoTextsModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * A complementary UI component for the PivotGrid that allows you to manage data displayed in the PivotGrid. The field chooser is already integrated in the PivotGrid and can be invoked using the context menu. If you need to continuously display the field chooser near the PivotGrid UI component, use the PivotGridFieldChooser UI component.

 */
class DxPivotGridFieldChooserComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this._createEventEmitters([
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'contextMenuPreparing', emit: 'onContextMenuPreparing' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { emit: 'accessKeyChange' },
            { emit: 'activeStateEnabledChange' },
            { emit: 'allowSearchChange' },
            { emit: 'applyChangesModeChange' },
            { emit: 'dataSourceChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'encodeHtmlChange' },
            { emit: 'focusStateEnabledChange' },
            { emit: 'headerFilterChange' },
            { emit: 'heightChange' },
            { emit: 'hintChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'layoutChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'searchTimeoutChange' },
            { emit: 'stateChange' },
            { emit: 'tabIndexChange' },
            { emit: 'textsChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * Specifies the shortcut key that sets focus on the UI component.
    
     */
    get accessKey() {
        return this._getOption('accessKey');
    }
    set accessKey(value) {
        this._setOption('accessKey', value);
    }
    /**
     * Specifies whether the UI component changes its visual state as a result of user interaction.
    
     */
    get activeStateEnabled() {
        return this._getOption('activeStateEnabled');
    }
    set activeStateEnabled(value) {
        this._setOption('activeStateEnabled', value);
    }
    /**
     * Specifies whether the field chooser allows search operations in the &apos;All Fields&apos; section.
    
     */
    get allowSearch() {
        return this._getOption('allowSearch');
    }
    set allowSearch(value) {
        this._setOption('allowSearch', value);
    }
    /**
     * Specifies when to apply changes made in the UI component to the PivotGrid.
    
     */
    get applyChangesMode() {
        return this._getOption('applyChangesMode');
    }
    set applyChangesMode(value) {
        this._setOption('applyChangesMode', value);
    }
    /**
     * The data source of a PivotGrid UI component.
    
     */
    get dataSource() {
        return this._getOption('dataSource');
    }
    set dataSource(value) {
        this._setOption('dataSource', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies whether HTML tags are displayed as plain text or applied to the values of the header filter.
    
     */
    get encodeHtml() {
        return this._getOption('encodeHtml');
    }
    set encodeHtml(value) {
        this._setOption('encodeHtml', value);
    }
    /**
     * Specifies whether the UI component can be focused using keyboard navigation.
    
     */
    get focusStateEnabled() {
        return this._getOption('focusStateEnabled');
    }
    set focusStateEnabled(value) {
        this._setOption('focusStateEnabled', value);
    }
    /**
     * Configures the header filter feature.
    
     */
    get headerFilter() {
        return this._getOption('headerFilter');
    }
    set headerFilter(value) {
        this._setOption('headerFilter', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * Specifies the field chooser layout.
    
     */
    get layout() {
        return this._getOption('layout');
    }
    set layout(value) {
        this._setOption('layout', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies a delay in milliseconds between when a user finishes typing in the field chooser&apos;s search panel, and when the search is executed.
    
     */
    get searchTimeout() {
        return this._getOption('searchTimeout');
    }
    set searchTimeout(value) {
        this._setOption('searchTimeout', value);
    }
    /**
     * The UI component&apos;s state.
    
     */
    get state() {
        return this._getOption('state');
    }
    set state(value) {
        this._setOption('state', value);
    }
    /**
     * Specifies the number of the element when the Tab key is used for navigating.
    
     */
    get tabIndex() {
        return this._getOption('tabIndex');
    }
    set tabIndex(value) {
        this._setOption('tabIndex', value);
    }
    /**
     * Strings that can be changed or localized in the PivotGridFieldChooser UI component.
    
     */
    get texts() {
        return this._getOption('texts');
    }
    set texts(value) {
        this._setOption('texts', value);
    }
    /**
     * Specifies whether the UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    _createInstance(element, options) {
        return new DxPivotGridFieldChooser(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('dataSource', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('dataSource');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxPivotGridFieldChooserComponent.ɵfac = function DxPivotGridFieldChooserComponent_Factory(t) { return new (t || DxPivotGridFieldChooserComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxPivotGridFieldChooserComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxPivotGridFieldChooserComponent, selectors: [["dx-pivot-grid-field-chooser"]], inputs: { accessKey: "accessKey", activeStateEnabled: "activeStateEnabled", allowSearch: "allowSearch", applyChangesMode: "applyChangesMode", dataSource: "dataSource", disabled: "disabled", elementAttr: "elementAttr", encodeHtml: "encodeHtml", focusStateEnabled: "focusStateEnabled", headerFilter: "headerFilter", height: "height", hint: "hint", hoverStateEnabled: "hoverStateEnabled", layout: "layout", rtlEnabled: "rtlEnabled", searchTimeout: "searchTimeout", state: "state", tabIndex: "tabIndex", texts: "texts", visible: "visible", width: "width" }, outputs: { onContentReady: "onContentReady", onContextMenuPreparing: "onContextMenuPreparing", onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", accessKeyChange: "accessKeyChange", activeStateEnabledChange: "activeStateEnabledChange", allowSearchChange: "allowSearchChange", applyChangesModeChange: "applyChangesModeChange", dataSourceChange: "dataSourceChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", encodeHtmlChange: "encodeHtmlChange", focusStateEnabledChange: "focusStateEnabledChange", headerFilterChange: "headerFilterChange", heightChange: "heightChange", hintChange: "hintChange", hoverStateEnabledChange: "hoverStateEnabledChange", layoutChange: "layoutChange", rtlEnabledChange: "rtlEnabledChange", searchTimeoutChange: "searchTimeoutChange", stateChange: "stateChange", tabIndexChange: "tabIndexChange", textsChange: "textsChange", visibleChange: "visibleChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxPivotGridFieldChooserComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPivotGridFieldChooserComponent, [{
        type: Component,
        args: [{
                selector: 'dx-pivot-grid-field-chooser',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { accessKey: [{
            type: Input
        }], activeStateEnabled: [{
            type: Input
        }], allowSearch: [{
            type: Input
        }], applyChangesMode: [{
            type: Input
        }], dataSource: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], encodeHtml: [{
            type: Input
        }], focusStateEnabled: [{
            type: Input
        }], headerFilter: [{
            type: Input
        }], height: [{
            type: Input
        }], hint: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], layout: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], searchTimeout: [{
            type: Input
        }], state: [{
            type: Input
        }], tabIndex: [{
            type: Input
        }], texts: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], onContentReady: [{
            type: Output
        }], onContextMenuPreparing: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], accessKeyChange: [{
            type: Output
        }], activeStateEnabledChange: [{
            type: Output
        }], allowSearchChange: [{
            type: Output
        }], applyChangesModeChange: [{
            type: Output
        }], dataSourceChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], encodeHtmlChange: [{
            type: Output
        }], focusStateEnabledChange: [{
            type: Output
        }], headerFilterChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], layoutChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], searchTimeoutChange: [{
            type: Output
        }], stateChange: [{
            type: Output
        }], tabIndexChange: [{
            type: Output
        }], textsChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }] }); })();
class DxPivotGridFieldChooserModule {
}
/** @nocollapse */ DxPivotGridFieldChooserModule.ɵfac = function DxPivotGridFieldChooserModule_Factory(t) { return new (t || DxPivotGridFieldChooserModule)(); };
/** @nocollapse */ DxPivotGridFieldChooserModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxPivotGridFieldChooserModule });
/** @nocollapse */ DxPivotGridFieldChooserModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxoHeaderFilterModule,
            DxoSearchModule,
            DxoTextsModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxoHeaderFilterModule,
        DxoSearchModule,
        DxoTextsModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxPivotGridFieldChooserModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxoHeaderFilterModule,
                    DxoSearchModule,
                    DxoTextsModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxPivotGridFieldChooserComponent
                ],
                exports: [
                    DxPivotGridFieldChooserComponent,
                    DxoHeaderFilterModule,
                    DxoSearchModule,
                    DxoTextsModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxPivotGridFieldChooserModule, { declarations: [DxPivotGridFieldChooserComponent], imports: [DxoHeaderFilterModule,
        DxoSearchModule,
        DxoTextsModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxPivotGridFieldChooserComponent, DxoHeaderFilterModule,
        DxoSearchModule,
        DxoTextsModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxPivotGridFieldChooserComponent, DxPivotGridFieldChooserModule };

