import * as i0 from '@angular/core';
import { forwardRef, PLATFORM_ID, Component, Inject, Input, Output, HostListener, NgModule } from '@angular/core';
import DxRangeSelector from 'devextreme/viz/range_selector';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxoBackgroundModule, DxoImageModule, DxoBehaviorModule, DxoChartModule, DxoCommonSeriesSettingsModule, DxoAggregationModule, DxoAreaModule, DxoBorderModule, DxoHoverStyleModule, DxoHatchingModule, DxoLabelModule, DxoConnectorModule, DxoPointModule, DxoHeightModule, DxoUrlModule, DxoWidthModule, DxoSelectionStyleModule, DxoReductionModule, DxoValueErrorBarModule, DxoBarModule, DxoBubbleModule, DxoCandlestickModule, DxoColorModule, DxoFullstackedareaModule, DxoFullstackedbarModule, DxoFullstackedlineModule, DxoFullstackedsplineModule, DxoFullstackedsplineareaModule, DxoArgumentFormatModule, DxoFontModule, DxoFormatModule, DxoLineModule, DxoRangeareaModule, DxoRangebarModule, DxoScatterModule, DxoSplineModule, DxoSplineareaModule, DxoStackedareaModule, DxoStackedbarModule, DxoStackedlineModule, DxoStackedsplineModule, DxoStackedsplineareaModule, DxoStepareaModule, DxoSteplineModule, DxoStockModule, DxoDataPrepareSettingsModule, DxiSeriesModule, DxoSeriesTemplateModule, DxoValueAxisModule, DxoExportModule, DxoIndentModule, DxoLoadingIndicatorModule, DxoMarginModule, DxoScaleModule, DxoAggregationIntervalModule, DxiBreakModule, DxoBreakStyleModule, DxoMarkerModule, DxoMaxRangeModule, DxoMinorTickModule, DxoMinorTickIntervalModule, DxoMinRangeModule, DxoTickModule, DxoTickIntervalModule, DxoShutterModule, DxoSizeModule, DxoSliderHandleModule, DxoSliderMarkerModule, DxoTitleModule, DxoSubtitleModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

const CUSTOM_VALUE_ACCESSOR_PROVIDER = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DxRangeSelectorComponent),
    multi: true
};
/**
 * The RangeSelector is a UI component that allows a user to select a range of values on a scale.

 */
class DxRangeSelectorComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this.touched = (_) => { };
        this._createEventEmitters([
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'drawn', emit: 'onDrawn' },
            { subscribe: 'exported', emit: 'onExported' },
            { subscribe: 'exporting', emit: 'onExporting' },
            { subscribe: 'fileSaving', emit: 'onFileSaving' },
            { subscribe: 'incidentOccurred', emit: 'onIncidentOccurred' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'valueChanged', emit: 'onValueChanged' },
            { emit: 'backgroundChange' },
            { emit: 'behaviorChange' },
            { emit: 'chartChange' },
            { emit: 'containerBackgroundColorChange' },
            { emit: 'dataSourceChange' },
            { emit: 'dataSourceFieldChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'exportChange' },
            { emit: 'indentChange' },
            { emit: 'loadingIndicatorChange' },
            { emit: 'marginChange' },
            { emit: 'pathModifiedChange' },
            { emit: 'redrawOnResizeChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'scaleChange' },
            { emit: 'selectedRangeColorChange' },
            { emit: 'selectedRangeUpdateModeChange' },
            { emit: 'shutterChange' },
            { emit: 'sizeChange' },
            { emit: 'sliderHandleChange' },
            { emit: 'sliderMarkerChange' },
            { emit: 'themeChange' },
            { emit: 'titleChange' },
            { emit: 'valueChange' },
            { emit: 'onBlur' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * Specifies the properties for the range selector&apos;s background.
    
     */
    get background() {
        return this._getOption('background');
    }
    set background(value) {
        this._setOption('background', value);
    }
    /**
     * Specifies the RangeSelector&apos;s behavior properties.
    
     */
    get behavior() {
        return this._getOption('behavior');
    }
    set behavior(value) {
        this._setOption('behavior', value);
    }
    /**
     * Specifies the properties required to display a chart as the range selector&apos;s background.
    
     */
    get chart() {
        return this._getOption('chart');
    }
    set chart(value) {
        this._setOption('chart', value);
    }
    /**
     * Specifies the color of the parent page element.
    
     */
    get containerBackgroundColor() {
        return this._getOption('containerBackgroundColor');
    }
    set containerBackgroundColor(value) {
        this._setOption('containerBackgroundColor', value);
    }
    /**
     * Specifies a data source for the scale values and for the chart at the background.
    
     */
    get dataSource() {
        return this._getOption('dataSource');
    }
    set dataSource(value) {
        this._setOption('dataSource', value);
    }
    /**
     * Specifies the data source field that provides data for the scale.
    
     */
    get dataSourceField() {
        return this._getOption('dataSourceField');
    }
    set dataSourceField(value) {
        this._setOption('dataSourceField', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Configures the exporting and printing features.
    
     */
    get export() {
        return this._getOption('export');
    }
    set export(value) {
        this._setOption('export', value);
    }
    /**
     * Range selector&apos;s indent properties.
    
     */
    get indent() {
        return this._getOption('indent');
    }
    set indent(value) {
        this._setOption('indent', value);
    }
    /**
     * Configures the loading indicator.
    
     */
    get loadingIndicator() {
        return this._getOption('loadingIndicator');
    }
    set loadingIndicator(value) {
        this._setOption('loadingIndicator', value);
    }
    /**
     * Generates space around the UI component.
    
     */
    get margin() {
        return this._getOption('margin');
    }
    set margin(value) {
        this._setOption('margin', value);
    }
    /**
     * Notifies the UI component that it is embedded into an HTML page that uses a tag modifying the path.
    
     */
    get pathModified() {
        return this._getOption('pathModified');
    }
    set pathModified(value) {
        this._setOption('pathModified', value);
    }
    /**
     * Specifies whether to redraw the UI component when the size of the container changes or a mobile device rotates.
    
     */
    get redrawOnResize() {
        return this._getOption('redrawOnResize');
    }
    set redrawOnResize(value) {
        this._setOption('redrawOnResize', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies properties of the range selector&apos;s scale.
    
     */
    get scale() {
        return this._getOption('scale');
    }
    set scale(value) {
        this._setOption('scale', value);
    }
    /**
     * Specifies the color of the selected range.
    
     */
    get selectedRangeColor() {
        return this._getOption('selectedRangeColor');
    }
    set selectedRangeColor(value) {
        this._setOption('selectedRangeColor', value);
    }
    /**
     * Specifies how the selected range should behave when data is updated. Applies only when the RangeSelector is bound to a data source.
    
     */
    get selectedRangeUpdateMode() {
        return this._getOption('selectedRangeUpdateMode');
    }
    set selectedRangeUpdateMode(value) {
        this._setOption('selectedRangeUpdateMode', value);
    }
    /**
     * Specifies range selector shutter properties.
    
     */
    get shutter() {
        return this._getOption('shutter');
    }
    set shutter(value) {
        this._setOption('shutter', value);
    }
    /**
     * Specifies the UI component&apos;s size in pixels.
    
     */
    get size() {
        return this._getOption('size');
    }
    set size(value) {
        this._setOption('size', value);
    }
    /**
     * Specifies the appearance of the range selector&apos;s slider handles.
    
     */
    get sliderHandle() {
        return this._getOption('sliderHandle');
    }
    set sliderHandle(value) {
        this._setOption('sliderHandle', value);
    }
    /**
     * Defines the properties of the range selector slider markers.
    
     */
    get sliderMarker() {
        return this._getOption('sliderMarker');
    }
    set sliderMarker(value) {
        this._setOption('sliderMarker', value);
    }
    /**
     * Sets the name of the theme the UI component uses.
    
     */
    get theme() {
        return this._getOption('theme');
    }
    set theme(value) {
        this._setOption('theme', value);
    }
    /**
     * Configures the UI component&apos;s title.
    
     */
    get title() {
        return this._getOption('title');
    }
    set title(value) {
        this._setOption('title', value);
    }
    /**
     * The selected range (initial or current). Equals the entire scale when not set.
    
     */
    get value() {
        return this._getOption('value');
    }
    set value(value) {
        this._setOption('value', value);
    }
    change(_) { }
    _createInstance(element, options) {
        return new DxRangeSelector(element, options);
    }
    writeValue(value) {
        this.eventHelper.lockedValueChangeEvent = true;
        this.value = value;
        this.eventHelper.lockedValueChangeEvent = false;
    }
    registerOnChange(fn) { this.change = fn; }
    registerOnTouched(fn) { this.touched = fn; }
    _createWidget(element) {
        super._createWidget(element);
        this.instance.on('focusOut', (e) => {
            this.eventHelper.fireNgEvent('onBlur', [e]);
        });
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('dataSource', changes);
        this.setupChanges('value', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('dataSource');
        this._idh.doCheck('value');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxRangeSelectorComponent.ɵfac = function DxRangeSelectorComponent_Factory(t) { return new (t || DxRangeSelectorComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxRangeSelectorComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxRangeSelectorComponent, selectors: [["dx-range-selector"]], hostBindings: function DxRangeSelectorComponent_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("valueChange", function DxRangeSelectorComponent_valueChange_HostBindingHandler($event) { return ctx.change($event); })("onBlur", function DxRangeSelectorComponent_onBlur_HostBindingHandler($event) { return ctx.touched($event); });
    } }, inputs: { background: "background", behavior: "behavior", chart: "chart", containerBackgroundColor: "containerBackgroundColor", dataSource: "dataSource", dataSourceField: "dataSourceField", disabled: "disabled", elementAttr: "elementAttr", export: "export", indent: "indent", loadingIndicator: "loadingIndicator", margin: "margin", pathModified: "pathModified", redrawOnResize: "redrawOnResize", rtlEnabled: "rtlEnabled", scale: "scale", selectedRangeColor: "selectedRangeColor", selectedRangeUpdateMode: "selectedRangeUpdateMode", shutter: "shutter", size: "size", sliderHandle: "sliderHandle", sliderMarker: "sliderMarker", theme: "theme", title: "title", value: "value" }, outputs: { onDisposing: "onDisposing", onDrawn: "onDrawn", onExported: "onExported", onExporting: "onExporting", onFileSaving: "onFileSaving", onIncidentOccurred: "onIncidentOccurred", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onValueChanged: "onValueChanged", backgroundChange: "backgroundChange", behaviorChange: "behaviorChange", chartChange: "chartChange", containerBackgroundColorChange: "containerBackgroundColorChange", dataSourceChange: "dataSourceChange", dataSourceFieldChange: "dataSourceFieldChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", exportChange: "exportChange", indentChange: "indentChange", loadingIndicatorChange: "loadingIndicatorChange", marginChange: "marginChange", pathModifiedChange: "pathModifiedChange", redrawOnResizeChange: "redrawOnResizeChange", rtlEnabledChange: "rtlEnabledChange", scaleChange: "scaleChange", selectedRangeColorChange: "selectedRangeColorChange", selectedRangeUpdateModeChange: "selectedRangeUpdateModeChange", shutterChange: "shutterChange", sizeChange: "sizeChange", sliderHandleChange: "sliderHandleChange", sliderMarkerChange: "sliderMarkerChange", themeChange: "themeChange", titleChange: "titleChange", valueChange: "valueChange", onBlur: "onBlur" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            CUSTOM_VALUE_ACCESSOR_PROVIDER,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxRangeSelectorComponent_Template(rf, ctx) { }, styles: ["[_nghost-%COMP%] {  display: block; }"] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxRangeSelectorComponent, [{
        type: Component,
        args: [{
                selector: 'dx-range-selector',
                template: '',
                styles: [' :host {  display: block; }'],
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    CUSTOM_VALUE_ACCESSOR_PROVIDER,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { background: [{
            type: Input
        }], behavior: [{
            type: Input
        }], chart: [{
            type: Input
        }], containerBackgroundColor: [{
            type: Input
        }], dataSource: [{
            type: Input
        }], dataSourceField: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], export: [{
            type: Input
        }], indent: [{
            type: Input
        }], loadingIndicator: [{
            type: Input
        }], margin: [{
            type: Input
        }], pathModified: [{
            type: Input
        }], redrawOnResize: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], scale: [{
            type: Input
        }], selectedRangeColor: [{
            type: Input
        }], selectedRangeUpdateMode: [{
            type: Input
        }], shutter: [{
            type: Input
        }], size: [{
            type: Input
        }], sliderHandle: [{
            type: Input
        }], sliderMarker: [{
            type: Input
        }], theme: [{
            type: Input
        }], title: [{
            type: Input
        }], value: [{
            type: Input
        }], onDisposing: [{
            type: Output
        }], onDrawn: [{
            type: Output
        }], onExported: [{
            type: Output
        }], onExporting: [{
            type: Output
        }], onFileSaving: [{
            type: Output
        }], onIncidentOccurred: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onValueChanged: [{
            type: Output
        }], backgroundChange: [{
            type: Output
        }], behaviorChange: [{
            type: Output
        }], chartChange: [{
            type: Output
        }], containerBackgroundColorChange: [{
            type: Output
        }], dataSourceChange: [{
            type: Output
        }], dataSourceFieldChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], exportChange: [{
            type: Output
        }], indentChange: [{
            type: Output
        }], loadingIndicatorChange: [{
            type: Output
        }], marginChange: [{
            type: Output
        }], pathModifiedChange: [{
            type: Output
        }], redrawOnResizeChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], scaleChange: [{
            type: Output
        }], selectedRangeColorChange: [{
            type: Output
        }], selectedRangeUpdateModeChange: [{
            type: Output
        }], shutterChange: [{
            type: Output
        }], sizeChange: [{
            type: Output
        }], sliderHandleChange: [{
            type: Output
        }], sliderMarkerChange: [{
            type: Output
        }], themeChange: [{
            type: Output
        }], titleChange: [{
            type: Output
        }], valueChange: [{
            type: Output
        }], onBlur: [{
            type: Output
        }], change: [{
            type: HostListener,
            args: ['valueChange', ['$event']]
        }], touched: [{
            type: HostListener,
            args: ['onBlur', ['$event']]
        }] }); })();
class DxRangeSelectorModule {
}
/** @nocollapse */ DxRangeSelectorModule.ɵfac = function DxRangeSelectorModule_Factory(t) { return new (t || DxRangeSelectorModule)(); };
/** @nocollapse */ DxRangeSelectorModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxRangeSelectorModule });
/** @nocollapse */ DxRangeSelectorModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxoBackgroundModule,
            DxoImageModule,
            DxoBehaviorModule,
            DxoChartModule,
            DxoCommonSeriesSettingsModule,
            DxoAggregationModule,
            DxoAreaModule,
            DxoBorderModule,
            DxoHoverStyleModule,
            DxoHatchingModule,
            DxoLabelModule,
            DxoConnectorModule,
            DxoPointModule,
            DxoHeightModule,
            DxoUrlModule,
            DxoWidthModule,
            DxoSelectionStyleModule,
            DxoReductionModule,
            DxoValueErrorBarModule,
            DxoBarModule,
            DxoBubbleModule,
            DxoCandlestickModule,
            DxoColorModule,
            DxoFullstackedareaModule,
            DxoFullstackedbarModule,
            DxoFullstackedlineModule,
            DxoFullstackedsplineModule,
            DxoFullstackedsplineareaModule,
            DxoArgumentFormatModule,
            DxoFontModule,
            DxoFormatModule,
            DxoLineModule,
            DxoRangeareaModule,
            DxoRangebarModule,
            DxoScatterModule,
            DxoSplineModule,
            DxoSplineareaModule,
            DxoStackedareaModule,
            DxoStackedbarModule,
            DxoStackedlineModule,
            DxoStackedsplineModule,
            DxoStackedsplineareaModule,
            DxoStepareaModule,
            DxoSteplineModule,
            DxoStockModule,
            DxoDataPrepareSettingsModule,
            DxiSeriesModule,
            DxoSeriesTemplateModule,
            DxoValueAxisModule,
            DxoExportModule,
            DxoIndentModule,
            DxoLoadingIndicatorModule,
            DxoMarginModule,
            DxoScaleModule,
            DxoAggregationIntervalModule,
            DxiBreakModule,
            DxoBreakStyleModule,
            DxoMarkerModule,
            DxoMaxRangeModule,
            DxoMinorTickModule,
            DxoMinorTickIntervalModule,
            DxoMinRangeModule,
            DxoTickModule,
            DxoTickIntervalModule,
            DxoShutterModule,
            DxoSizeModule,
            DxoSliderHandleModule,
            DxoSliderMarkerModule,
            DxoTitleModule,
            DxoSubtitleModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxoBackgroundModule,
        DxoImageModule,
        DxoBehaviorModule,
        DxoChartModule,
        DxoCommonSeriesSettingsModule,
        DxoAggregationModule,
        DxoAreaModule,
        DxoBorderModule,
        DxoHoverStyleModule,
        DxoHatchingModule,
        DxoLabelModule,
        DxoConnectorModule,
        DxoPointModule,
        DxoHeightModule,
        DxoUrlModule,
        DxoWidthModule,
        DxoSelectionStyleModule,
        DxoReductionModule,
        DxoValueErrorBarModule,
        DxoBarModule,
        DxoBubbleModule,
        DxoCandlestickModule,
        DxoColorModule,
        DxoFullstackedareaModule,
        DxoFullstackedbarModule,
        DxoFullstackedlineModule,
        DxoFullstackedsplineModule,
        DxoFullstackedsplineareaModule,
        DxoArgumentFormatModule,
        DxoFontModule,
        DxoFormatModule,
        DxoLineModule,
        DxoRangeareaModule,
        DxoRangebarModule,
        DxoScatterModule,
        DxoSplineModule,
        DxoSplineareaModule,
        DxoStackedareaModule,
        DxoStackedbarModule,
        DxoStackedlineModule,
        DxoStackedsplineModule,
        DxoStackedsplineareaModule,
        DxoStepareaModule,
        DxoSteplineModule,
        DxoStockModule,
        DxoDataPrepareSettingsModule,
        DxiSeriesModule,
        DxoSeriesTemplateModule,
        DxoValueAxisModule,
        DxoExportModule,
        DxoIndentModule,
        DxoLoadingIndicatorModule,
        DxoMarginModule,
        DxoScaleModule,
        DxoAggregationIntervalModule,
        DxiBreakModule,
        DxoBreakStyleModule,
        DxoMarkerModule,
        DxoMaxRangeModule,
        DxoMinorTickModule,
        DxoMinorTickIntervalModule,
        DxoMinRangeModule,
        DxoTickModule,
        DxoTickIntervalModule,
        DxoShutterModule,
        DxoSizeModule,
        DxoSliderHandleModule,
        DxoSliderMarkerModule,
        DxoTitleModule,
        DxoSubtitleModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxRangeSelectorModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxoBackgroundModule,
                    DxoImageModule,
                    DxoBehaviorModule,
                    DxoChartModule,
                    DxoCommonSeriesSettingsModule,
                    DxoAggregationModule,
                    DxoAreaModule,
                    DxoBorderModule,
                    DxoHoverStyleModule,
                    DxoHatchingModule,
                    DxoLabelModule,
                    DxoConnectorModule,
                    DxoPointModule,
                    DxoHeightModule,
                    DxoUrlModule,
                    DxoWidthModule,
                    DxoSelectionStyleModule,
                    DxoReductionModule,
                    DxoValueErrorBarModule,
                    DxoBarModule,
                    DxoBubbleModule,
                    DxoCandlestickModule,
                    DxoColorModule,
                    DxoFullstackedareaModule,
                    DxoFullstackedbarModule,
                    DxoFullstackedlineModule,
                    DxoFullstackedsplineModule,
                    DxoFullstackedsplineareaModule,
                    DxoArgumentFormatModule,
                    DxoFontModule,
                    DxoFormatModule,
                    DxoLineModule,
                    DxoRangeareaModule,
                    DxoRangebarModule,
                    DxoScatterModule,
                    DxoSplineModule,
                    DxoSplineareaModule,
                    DxoStackedareaModule,
                    DxoStackedbarModule,
                    DxoStackedlineModule,
                    DxoStackedsplineModule,
                    DxoStackedsplineareaModule,
                    DxoStepareaModule,
                    DxoSteplineModule,
                    DxoStockModule,
                    DxoDataPrepareSettingsModule,
                    DxiSeriesModule,
                    DxoSeriesTemplateModule,
                    DxoValueAxisModule,
                    DxoExportModule,
                    DxoIndentModule,
                    DxoLoadingIndicatorModule,
                    DxoMarginModule,
                    DxoScaleModule,
                    DxoAggregationIntervalModule,
                    DxiBreakModule,
                    DxoBreakStyleModule,
                    DxoMarkerModule,
                    DxoMaxRangeModule,
                    DxoMinorTickModule,
                    DxoMinorTickIntervalModule,
                    DxoMinRangeModule,
                    DxoTickModule,
                    DxoTickIntervalModule,
                    DxoShutterModule,
                    DxoSizeModule,
                    DxoSliderHandleModule,
                    DxoSliderMarkerModule,
                    DxoTitleModule,
                    DxoSubtitleModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxRangeSelectorComponent
                ],
                exports: [
                    DxRangeSelectorComponent,
                    DxoBackgroundModule,
                    DxoImageModule,
                    DxoBehaviorModule,
                    DxoChartModule,
                    DxoCommonSeriesSettingsModule,
                    DxoAggregationModule,
                    DxoAreaModule,
                    DxoBorderModule,
                    DxoHoverStyleModule,
                    DxoHatchingModule,
                    DxoLabelModule,
                    DxoConnectorModule,
                    DxoPointModule,
                    DxoHeightModule,
                    DxoUrlModule,
                    DxoWidthModule,
                    DxoSelectionStyleModule,
                    DxoReductionModule,
                    DxoValueErrorBarModule,
                    DxoBarModule,
                    DxoBubbleModule,
                    DxoCandlestickModule,
                    DxoColorModule,
                    DxoFullstackedareaModule,
                    DxoFullstackedbarModule,
                    DxoFullstackedlineModule,
                    DxoFullstackedsplineModule,
                    DxoFullstackedsplineareaModule,
                    DxoArgumentFormatModule,
                    DxoFontModule,
                    DxoFormatModule,
                    DxoLineModule,
                    DxoRangeareaModule,
                    DxoRangebarModule,
                    DxoScatterModule,
                    DxoSplineModule,
                    DxoSplineareaModule,
                    DxoStackedareaModule,
                    DxoStackedbarModule,
                    DxoStackedlineModule,
                    DxoStackedsplineModule,
                    DxoStackedsplineareaModule,
                    DxoStepareaModule,
                    DxoSteplineModule,
                    DxoStockModule,
                    DxoDataPrepareSettingsModule,
                    DxiSeriesModule,
                    DxoSeriesTemplateModule,
                    DxoValueAxisModule,
                    DxoExportModule,
                    DxoIndentModule,
                    DxoLoadingIndicatorModule,
                    DxoMarginModule,
                    DxoScaleModule,
                    DxoAggregationIntervalModule,
                    DxiBreakModule,
                    DxoBreakStyleModule,
                    DxoMarkerModule,
                    DxoMaxRangeModule,
                    DxoMinorTickModule,
                    DxoMinorTickIntervalModule,
                    DxoMinRangeModule,
                    DxoTickModule,
                    DxoTickIntervalModule,
                    DxoShutterModule,
                    DxoSizeModule,
                    DxoSliderHandleModule,
                    DxoSliderMarkerModule,
                    DxoTitleModule,
                    DxoSubtitleModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxRangeSelectorModule, { declarations: [DxRangeSelectorComponent], imports: [DxoBackgroundModule,
        DxoImageModule,
        DxoBehaviorModule,
        DxoChartModule,
        DxoCommonSeriesSettingsModule,
        DxoAggregationModule,
        DxoAreaModule,
        DxoBorderModule,
        DxoHoverStyleModule,
        DxoHatchingModule,
        DxoLabelModule,
        DxoConnectorModule,
        DxoPointModule,
        DxoHeightModule,
        DxoUrlModule,
        DxoWidthModule,
        DxoSelectionStyleModule,
        DxoReductionModule,
        DxoValueErrorBarModule,
        DxoBarModule,
        DxoBubbleModule,
        DxoCandlestickModule,
        DxoColorModule,
        DxoFullstackedareaModule,
        DxoFullstackedbarModule,
        DxoFullstackedlineModule,
        DxoFullstackedsplineModule,
        DxoFullstackedsplineareaModule,
        DxoArgumentFormatModule,
        DxoFontModule,
        DxoFormatModule,
        DxoLineModule,
        DxoRangeareaModule,
        DxoRangebarModule,
        DxoScatterModule,
        DxoSplineModule,
        DxoSplineareaModule,
        DxoStackedareaModule,
        DxoStackedbarModule,
        DxoStackedlineModule,
        DxoStackedsplineModule,
        DxoStackedsplineareaModule,
        DxoStepareaModule,
        DxoSteplineModule,
        DxoStockModule,
        DxoDataPrepareSettingsModule,
        DxiSeriesModule,
        DxoSeriesTemplateModule,
        DxoValueAxisModule,
        DxoExportModule,
        DxoIndentModule,
        DxoLoadingIndicatorModule,
        DxoMarginModule,
        DxoScaleModule,
        DxoAggregationIntervalModule,
        DxiBreakModule,
        DxoBreakStyleModule,
        DxoMarkerModule,
        DxoMaxRangeModule,
        DxoMinorTickModule,
        DxoMinorTickIntervalModule,
        DxoMinRangeModule,
        DxoTickModule,
        DxoTickIntervalModule,
        DxoShutterModule,
        DxoSizeModule,
        DxoSliderHandleModule,
        DxoSliderMarkerModule,
        DxoTitleModule,
        DxoSubtitleModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxRangeSelectorComponent, DxoBackgroundModule,
        DxoImageModule,
        DxoBehaviorModule,
        DxoChartModule,
        DxoCommonSeriesSettingsModule,
        DxoAggregationModule,
        DxoAreaModule,
        DxoBorderModule,
        DxoHoverStyleModule,
        DxoHatchingModule,
        DxoLabelModule,
        DxoConnectorModule,
        DxoPointModule,
        DxoHeightModule,
        DxoUrlModule,
        DxoWidthModule,
        DxoSelectionStyleModule,
        DxoReductionModule,
        DxoValueErrorBarModule,
        DxoBarModule,
        DxoBubbleModule,
        DxoCandlestickModule,
        DxoColorModule,
        DxoFullstackedareaModule,
        DxoFullstackedbarModule,
        DxoFullstackedlineModule,
        DxoFullstackedsplineModule,
        DxoFullstackedsplineareaModule,
        DxoArgumentFormatModule,
        DxoFontModule,
        DxoFormatModule,
        DxoLineModule,
        DxoRangeareaModule,
        DxoRangebarModule,
        DxoScatterModule,
        DxoSplineModule,
        DxoSplineareaModule,
        DxoStackedareaModule,
        DxoStackedbarModule,
        DxoStackedlineModule,
        DxoStackedsplineModule,
        DxoStackedsplineareaModule,
        DxoStepareaModule,
        DxoSteplineModule,
        DxoStockModule,
        DxoDataPrepareSettingsModule,
        DxiSeriesModule,
        DxoSeriesTemplateModule,
        DxoValueAxisModule,
        DxoExportModule,
        DxoIndentModule,
        DxoLoadingIndicatorModule,
        DxoMarginModule,
        DxoScaleModule,
        DxoAggregationIntervalModule,
        DxiBreakModule,
        DxoBreakStyleModule,
        DxoMarkerModule,
        DxoMaxRangeModule,
        DxoMinorTickModule,
        DxoMinorTickIntervalModule,
        DxoMinRangeModule,
        DxoTickModule,
        DxoTickIntervalModule,
        DxoShutterModule,
        DxoSizeModule,
        DxoSliderHandleModule,
        DxoSliderMarkerModule,
        DxoTitleModule,
        DxoSubtitleModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxRangeSelectorComponent, DxRangeSelectorModule };

