import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, ContentChildren, NgModule } from '@angular/core';
import DxMap from 'devextreme/ui/map';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxiCenterComponent, DxiMarkerComponent, DxiRouteComponent, DxoApiKeyModule, DxiCenterModule, DxiMarkerModule, DxiLocationModule, DxoTooltipModule, DxiRouteModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * The Map is an interactive UI component that displays a geographic map with markers and routes.

 */
class DxMapComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this._createEventEmitters([
            { subscribe: 'click', emit: 'onClick' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'markerAdded', emit: 'onMarkerAdded' },
            { subscribe: 'markerRemoved', emit: 'onMarkerRemoved' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'ready', emit: 'onReady' },
            { subscribe: 'routeAdded', emit: 'onRouteAdded' },
            { subscribe: 'routeRemoved', emit: 'onRouteRemoved' },
            { emit: 'accessKeyChange' },
            { emit: 'activeStateEnabledChange' },
            { emit: 'apiKeyChange' },
            { emit: 'autoAdjustChange' },
            { emit: 'centerChange' },
            { emit: 'controlsChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'focusStateEnabledChange' },
            { emit: 'heightChange' },
            { emit: 'hintChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'markerIconSrcChange' },
            { emit: 'markersChange' },
            { emit: 'providerChange' },
            { emit: 'routesChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'tabIndexChange' },
            { emit: 'typeChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' },
            { emit: 'zoomChange' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * Specifies the shortcut key that sets focus on the UI component.
    
     */
    get accessKey() {
        return this._getOption('accessKey');
    }
    set accessKey(value) {
        this._setOption('accessKey', value);
    }
    /**
     * Specifies whether the UI component changes its visual state as a result of user interaction.
    
     */
    get activeStateEnabled() {
        return this._getOption('activeStateEnabled');
    }
    set activeStateEnabled(value) {
        this._setOption('activeStateEnabled', value);
    }
    /**
     * Keys to authenticate the component within map providers.
    
     */
    get apiKey() {
        return this._getOption('apiKey');
    }
    set apiKey(value) {
        this._setOption('apiKey', value);
    }
    /**
     * Specifies whether the UI component automatically adjusts center and zoom property values when adding a new marker or route, or if a new UI component contains markers or routes by default.
    
     */
    get autoAdjust() {
        return this._getOption('autoAdjust');
    }
    set autoAdjust(value) {
        this._setOption('autoAdjust', value);
    }
    /**
     * An object, a string, or an array specifying which part of the map is displayed at the UI component&apos;s center using coordinates. The UI component can change this value if autoAdjust is enabled.
    
     */
    get center() {
        return this._getOption('center');
    }
    set center(value) {
        this._setOption('center', value);
    }
    /**
     * Specifies whether or not map UI component controls are available.
    
     */
    get controls() {
        return this._getOption('controls');
    }
    set controls(value) {
        this._setOption('controls', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies whether the UI component can be focused using keyboard navigation.
    
     */
    get focusStateEnabled() {
        return this._getOption('focusStateEnabled');
    }
    set focusStateEnabled(value) {
        this._setOption('focusStateEnabled', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * A URL pointing to the custom icon to be used for map markers.
    
     */
    get markerIconSrc() {
        return this._getOption('markerIconSrc');
    }
    set markerIconSrc(value) {
        this._setOption('markerIconSrc', value);
    }
    /**
     * An array of markers displayed on a map.
    
     */
    get markers() {
        return this._getOption('markers');
    }
    set markers(value) {
        this._setOption('markers', value);
    }
    /**
     * The name of the current map data provider.
    
     */
    get provider() {
        return this._getOption('provider');
    }
    set provider(value) {
        this._setOption('provider', value);
    }
    /**
     * An array of routes shown on the map.
    
     */
    get routes() {
        return this._getOption('routes');
    }
    set routes(value) {
        this._setOption('routes', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies the number of the element when the Tab key is used for navigating.
    
     */
    get tabIndex() {
        return this._getOption('tabIndex');
    }
    set tabIndex(value) {
        this._setOption('tabIndex', value);
    }
    /**
     * The type of a map to display.
    
     */
    get type() {
        return this._getOption('type');
    }
    set type(value) {
        this._setOption('type', value);
    }
    /**
     * Specifies whether the UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    /**
     * The map&apos;s zoom level. The UI component can change this value if autoAdjust is enabled.
    
     */
    get zoom() {
        return this._getOption('zoom');
    }
    set zoom(value) {
        this._setOption('zoom', value);
    }
    get centerChildren() {
        return this._getOption('center');
    }
    set centerChildren(value) {
        this.setChildren('center', value);
    }
    get markersChildren() {
        return this._getOption('markers');
    }
    set markersChildren(value) {
        this.setChildren('markers', value);
    }
    get routesChildren() {
        return this._getOption('routes');
    }
    set routesChildren(value) {
        this.setChildren('routes', value);
    }
    _createInstance(element, options) {
        return new DxMap(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('center', changes);
        this.setupChanges('markers', changes);
        this.setupChanges('routes', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('center');
        this._idh.doCheck('markers');
        this._idh.doCheck('routes');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxMapComponent.ɵfac = function DxMapComponent_Factory(t) { return new (t || DxMapComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxMapComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxMapComponent, selectors: [["dx-map"]], contentQueries: function DxMapComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, DxiCenterComponent, 4);
        i0.ɵɵcontentQuery(dirIndex, DxiMarkerComponent, 4);
        i0.ɵɵcontentQuery(dirIndex, DxiRouteComponent, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.centerChildren = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.markersChildren = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.routesChildren = _t);
    } }, inputs: { accessKey: "accessKey", activeStateEnabled: "activeStateEnabled", apiKey: "apiKey", autoAdjust: "autoAdjust", center: "center", controls: "controls", disabled: "disabled", elementAttr: "elementAttr", focusStateEnabled: "focusStateEnabled", height: "height", hint: "hint", hoverStateEnabled: "hoverStateEnabled", markerIconSrc: "markerIconSrc", markers: "markers", provider: "provider", routes: "routes", rtlEnabled: "rtlEnabled", tabIndex: "tabIndex", type: "type", visible: "visible", width: "width", zoom: "zoom" }, outputs: { onClick: "onClick", onDisposing: "onDisposing", onInitialized: "onInitialized", onMarkerAdded: "onMarkerAdded", onMarkerRemoved: "onMarkerRemoved", onOptionChanged: "onOptionChanged", onReady: "onReady", onRouteAdded: "onRouteAdded", onRouteRemoved: "onRouteRemoved", accessKeyChange: "accessKeyChange", activeStateEnabledChange: "activeStateEnabledChange", apiKeyChange: "apiKeyChange", autoAdjustChange: "autoAdjustChange", centerChange: "centerChange", controlsChange: "controlsChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", focusStateEnabledChange: "focusStateEnabledChange", heightChange: "heightChange", hintChange: "hintChange", hoverStateEnabledChange: "hoverStateEnabledChange", markerIconSrcChange: "markerIconSrcChange", markersChange: "markersChange", providerChange: "providerChange", routesChange: "routesChange", rtlEnabledChange: "rtlEnabledChange", tabIndexChange: "tabIndexChange", typeChange: "typeChange", visibleChange: "visibleChange", widthChange: "widthChange", zoomChange: "zoomChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxMapComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxMapComponent, [{
        type: Component,
        args: [{
                selector: 'dx-map',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { accessKey: [{
            type: Input
        }], activeStateEnabled: [{
            type: Input
        }], apiKey: [{
            type: Input
        }], autoAdjust: [{
            type: Input
        }], center: [{
            type: Input
        }], controls: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], focusStateEnabled: [{
            type: Input
        }], height: [{
            type: Input
        }], hint: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], markerIconSrc: [{
            type: Input
        }], markers: [{
            type: Input
        }], provider: [{
            type: Input
        }], routes: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], tabIndex: [{
            type: Input
        }], type: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], zoom: [{
            type: Input
        }], onClick: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onMarkerAdded: [{
            type: Output
        }], onMarkerRemoved: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onReady: [{
            type: Output
        }], onRouteAdded: [{
            type: Output
        }], onRouteRemoved: [{
            type: Output
        }], accessKeyChange: [{
            type: Output
        }], activeStateEnabledChange: [{
            type: Output
        }], apiKeyChange: [{
            type: Output
        }], autoAdjustChange: [{
            type: Output
        }], centerChange: [{
            type: Output
        }], controlsChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], focusStateEnabledChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], markerIconSrcChange: [{
            type: Output
        }], markersChange: [{
            type: Output
        }], providerChange: [{
            type: Output
        }], routesChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], tabIndexChange: [{
            type: Output
        }], typeChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }], zoomChange: [{
            type: Output
        }], centerChildren: [{
            type: ContentChildren,
            args: [DxiCenterComponent]
        }], markersChildren: [{
            type: ContentChildren,
            args: [DxiMarkerComponent]
        }], routesChildren: [{
            type: ContentChildren,
            args: [DxiRouteComponent]
        }] }); })();
class DxMapModule {
}
/** @nocollapse */ DxMapModule.ɵfac = function DxMapModule_Factory(t) { return new (t || DxMapModule)(); };
/** @nocollapse */ DxMapModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxMapModule });
/** @nocollapse */ DxMapModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxoApiKeyModule,
            DxiCenterModule,
            DxiMarkerModule,
            DxiLocationModule,
            DxoTooltipModule,
            DxiRouteModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxoApiKeyModule,
        DxiCenterModule,
        DxiMarkerModule,
        DxiLocationModule,
        DxoTooltipModule,
        DxiRouteModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxMapModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxoApiKeyModule,
                    DxiCenterModule,
                    DxiMarkerModule,
                    DxiLocationModule,
                    DxoTooltipModule,
                    DxiRouteModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxMapComponent
                ],
                exports: [
                    DxMapComponent,
                    DxoApiKeyModule,
                    DxiCenterModule,
                    DxiMarkerModule,
                    DxiLocationModule,
                    DxoTooltipModule,
                    DxiRouteModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxMapModule, { declarations: [DxMapComponent], imports: [DxoApiKeyModule,
        DxiCenterModule,
        DxiMarkerModule,
        DxiLocationModule,
        DxoTooltipModule,
        DxiRouteModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxMapComponent, DxoApiKeyModule,
        DxiCenterModule,
        DxiMarkerModule,
        DxiLocationModule,
        DxoTooltipModule,
        DxiRouteModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxMapComponent, DxMapModule };

