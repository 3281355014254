import * as i0 from '@angular/core';
import { forwardRef, PLATFORM_ID, Component, Inject, Input, Output, HostListener, NgModule } from '@angular/core';
import DxCheckBox from 'devextreme/ui/check_box';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import * as i2 from '@angular/platform-browser';

const CUSTOM_VALUE_ACCESSOR_PROVIDER = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DxCheckBoxComponent),
    multi: true
};
/**
 * The CheckBox is a small box, which when selected by the end user, shows that a particular feature has been enabled or a specific property has been chosen.

 */
class DxCheckBoxComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this.touched = (_) => { };
        this._createEventEmitters([
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'valueChanged', emit: 'onValueChanged' },
            { emit: 'accessKeyChange' },
            { emit: 'activeStateEnabledChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'enableThreeStateBehaviorChange' },
            { emit: 'focusStateEnabledChange' },
            { emit: 'heightChange' },
            { emit: 'hintChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'iconSizeChange' },
            { emit: 'isValidChange' },
            { emit: 'nameChange' },
            { emit: 'readOnlyChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'tabIndexChange' },
            { emit: 'textChange' },
            { emit: 'validationErrorChange' },
            { emit: 'validationErrorsChange' },
            { emit: 'validationMessageModeChange' },
            { emit: 'validationMessagePositionChange' },
            { emit: 'validationStatusChange' },
            { emit: 'valueChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' },
            { emit: 'onBlur' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * Specifies the shortcut key that sets focus on the UI component.
    
     */
    get accessKey() {
        return this._getOption('accessKey');
    }
    set accessKey(value) {
        this._setOption('accessKey', value);
    }
    /**
     * Specifies whether the UI component changes its visual state as a result of user interaction.
    
     */
    get activeStateEnabled() {
        return this._getOption('activeStateEnabled');
    }
    set activeStateEnabled(value) {
        this._setOption('activeStateEnabled', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies whether users can set the CheckBox state to indeterminate.
    
     */
    get enableThreeStateBehavior() {
        return this._getOption('enableThreeStateBehavior');
    }
    set enableThreeStateBehavior(value) {
        this._setOption('enableThreeStateBehavior', value);
    }
    /**
     * Specifies whether the UI component can be focused using keyboard navigation.
    
     */
    get focusStateEnabled() {
        return this._getOption('focusStateEnabled');
    }
    set focusStateEnabled(value) {
        this._setOption('focusStateEnabled', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * Specifies the check box icon&apos;s width and height.
    
     */
    get iconSize() {
        return this._getOption('iconSize');
    }
    set iconSize(value) {
        this._setOption('iconSize', value);
    }
    /**
     * Specifies or indicates whether the editor&apos;s value is valid.
    
     */
    get isValid() {
        return this._getOption('isValid');
    }
    set isValid(value) {
        this._setOption('isValid', value);
    }
    /**
     * The value to be assigned to the `name` attribute of the underlying HTML element.
    
     */
    get name() {
        return this._getOption('name');
    }
    set name(value) {
        this._setOption('name', value);
    }
    /**
     * Specifies whether the editor is read-only.
    
     */
    get readOnly() {
        return this._getOption('readOnly');
    }
    set readOnly(value) {
        this._setOption('readOnly', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies the number of the element when the Tab key is used for navigating.
    
     */
    get tabIndex() {
        return this._getOption('tabIndex');
    }
    set tabIndex(value) {
        this._setOption('tabIndex', value);
    }
    /**
     * Specifies the text displayed by the check box.
    
     */
    get text() {
        return this._getOption('text');
    }
    set text(value) {
        this._setOption('text', value);
    }
    /**
     * Information on the broken validation rule. Contains the first item from the validationErrors array.
    
     */
    get validationError() {
        return this._getOption('validationError');
    }
    set validationError(value) {
        this._setOption('validationError', value);
    }
    /**
     * An array of the validation rules that failed.
    
     */
    get validationErrors() {
        return this._getOption('validationErrors');
    }
    set validationErrors(value) {
        this._setOption('validationErrors', value);
    }
    /**
     * Specifies how the message about the validation rules that are not satisfied by this editor&apos;s value is displayed.
    
     */
    get validationMessageMode() {
        return this._getOption('validationMessageMode');
    }
    set validationMessageMode(value) {
        this._setOption('validationMessageMode', value);
    }
    /**
     * Specifies the position of a validation message relative to the component. The validation message describes the validation rules that this component&apos;s value does not satisfy.
    
     */
    get validationMessagePosition() {
        return this._getOption('validationMessagePosition');
    }
    set validationMessagePosition(value) {
        this._setOption('validationMessagePosition', value);
    }
    /**
     * Indicates or specifies the current validation status.
    
     */
    get validationStatus() {
        return this._getOption('validationStatus');
    }
    set validationStatus(value) {
        this._setOption('validationStatus', value);
    }
    /**
     * Specifies the UI component state.
    
     */
    get value() {
        return this._getOption('value');
    }
    set value(value) {
        this._setOption('value', value);
    }
    /**
     * Specifies whether the UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    change(_) { }
    _createInstance(element, options) {
        return new DxCheckBox(element, options);
    }
    writeValue(value) {
        this.eventHelper.lockedValueChangeEvent = true;
        this.value = value;
        this.eventHelper.lockedValueChangeEvent = false;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    registerOnChange(fn) { this.change = fn; }
    registerOnTouched(fn) { this.touched = fn; }
    _createWidget(element) {
        super._createWidget(element);
        this.instance.on('focusOut', (e) => {
            this.eventHelper.fireNgEvent('onBlur', [e]);
        });
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('validationErrors', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('validationErrors');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxCheckBoxComponent.ɵfac = function DxCheckBoxComponent_Factory(t) { return new (t || DxCheckBoxComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxCheckBoxComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxCheckBoxComponent, selectors: [["dx-check-box"]], hostBindings: function DxCheckBoxComponent_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("valueChange", function DxCheckBoxComponent_valueChange_HostBindingHandler($event) { return ctx.change($event); })("onBlur", function DxCheckBoxComponent_onBlur_HostBindingHandler($event) { return ctx.touched($event); });
    } }, inputs: { accessKey: "accessKey", activeStateEnabled: "activeStateEnabled", disabled: "disabled", elementAttr: "elementAttr", enableThreeStateBehavior: "enableThreeStateBehavior", focusStateEnabled: "focusStateEnabled", height: "height", hint: "hint", hoverStateEnabled: "hoverStateEnabled", iconSize: "iconSize", isValid: "isValid", name: "name", readOnly: "readOnly", rtlEnabled: "rtlEnabled", tabIndex: "tabIndex", text: "text", validationError: "validationError", validationErrors: "validationErrors", validationMessageMode: "validationMessageMode", validationMessagePosition: "validationMessagePosition", validationStatus: "validationStatus", value: "value", visible: "visible", width: "width" }, outputs: { onContentReady: "onContentReady", onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onValueChanged: "onValueChanged", accessKeyChange: "accessKeyChange", activeStateEnabledChange: "activeStateEnabledChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", enableThreeStateBehaviorChange: "enableThreeStateBehaviorChange", focusStateEnabledChange: "focusStateEnabledChange", heightChange: "heightChange", hintChange: "hintChange", hoverStateEnabledChange: "hoverStateEnabledChange", iconSizeChange: "iconSizeChange", isValidChange: "isValidChange", nameChange: "nameChange", readOnlyChange: "readOnlyChange", rtlEnabledChange: "rtlEnabledChange", tabIndexChange: "tabIndexChange", textChange: "textChange", validationErrorChange: "validationErrorChange", validationErrorsChange: "validationErrorsChange", validationMessageModeChange: "validationMessageModeChange", validationMessagePositionChange: "validationMessagePositionChange", validationStatusChange: "validationStatusChange", valueChange: "valueChange", visibleChange: "visibleChange", widthChange: "widthChange", onBlur: "onBlur" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            CUSTOM_VALUE_ACCESSOR_PROVIDER,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxCheckBoxComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCheckBoxComponent, [{
        type: Component,
        args: [{
                selector: 'dx-check-box',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    CUSTOM_VALUE_ACCESSOR_PROVIDER,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { accessKey: [{
            type: Input
        }], activeStateEnabled: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], enableThreeStateBehavior: [{
            type: Input
        }], focusStateEnabled: [{
            type: Input
        }], height: [{
            type: Input
        }], hint: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], iconSize: [{
            type: Input
        }], isValid: [{
            type: Input
        }], name: [{
            type: Input
        }], readOnly: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], tabIndex: [{
            type: Input
        }], text: [{
            type: Input
        }], validationError: [{
            type: Input
        }], validationErrors: [{
            type: Input
        }], validationMessageMode: [{
            type: Input
        }], validationMessagePosition: [{
            type: Input
        }], validationStatus: [{
            type: Input
        }], value: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], onContentReady: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onValueChanged: [{
            type: Output
        }], accessKeyChange: [{
            type: Output
        }], activeStateEnabledChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], enableThreeStateBehaviorChange: [{
            type: Output
        }], focusStateEnabledChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], iconSizeChange: [{
            type: Output
        }], isValidChange: [{
            type: Output
        }], nameChange: [{
            type: Output
        }], readOnlyChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], tabIndexChange: [{
            type: Output
        }], textChange: [{
            type: Output
        }], validationErrorChange: [{
            type: Output
        }], validationErrorsChange: [{
            type: Output
        }], validationMessageModeChange: [{
            type: Output
        }], validationMessagePositionChange: [{
            type: Output
        }], validationStatusChange: [{
            type: Output
        }], valueChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }], onBlur: [{
            type: Output
        }], change: [{
            type: HostListener,
            args: ['valueChange', ['$event']]
        }], touched: [{
            type: HostListener,
            args: ['onBlur', ['$event']]
        }] }); })();
class DxCheckBoxModule {
}
/** @nocollapse */ DxCheckBoxModule.ɵfac = function DxCheckBoxModule_Factory(t) { return new (t || DxCheckBoxModule)(); };
/** @nocollapse */ DxCheckBoxModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxCheckBoxModule });
/** @nocollapse */ DxCheckBoxModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxIntegrationModule,
            DxTemplateModule
        ], DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCheckBoxModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxCheckBoxComponent
                ],
                exports: [
                    DxCheckBoxComponent,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxCheckBoxModule, { declarations: [DxCheckBoxComponent], imports: [DxIntegrationModule,
        DxTemplateModule], exports: [DxCheckBoxComponent, DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxCheckBoxComponent, DxCheckBoxModule };

