import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxDrawer from 'devextreme/ui/drawer';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import * as i2 from '@angular/platform-browser';

const _c0 = ["*"];
/**
 * The Drawer is a dismissible or permanently visible panel used for navigation in responsive web application layouts.

 */
class DxDrawerComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._createEventEmitters([
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { emit: 'activeStateEnabledChange' },
            { emit: 'animationDurationChange' },
            { emit: 'animationEnabledChange' },
            { emit: 'closeOnOutsideClickChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'heightChange' },
            { emit: 'hintChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'maxSizeChange' },
            { emit: 'minSizeChange' },
            { emit: 'openedChange' },
            { emit: 'openedStateModeChange' },
            { emit: 'positionChange' },
            { emit: 'revealModeChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'shadingChange' },
            { emit: 'templateChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' }
        ]);
        optionHost.setHost(this);
    }
    /**
     * Specifies whether the UI component changes its visual state as a result of user interaction.
    
     */
    get activeStateEnabled() {
        return this._getOption('activeStateEnabled');
    }
    set activeStateEnabled(value) {
        this._setOption('activeStateEnabled', value);
    }
    /**
     * Specifies the duration of the drawer&apos;s opening and closing animation (in milliseconds). Applies only if animationEnabled is true.
    
     */
    get animationDuration() {
        return this._getOption('animationDuration');
    }
    set animationDuration(value) {
        this._setOption('animationDuration', value);
    }
    /**
     * Specifies whether to use an opening and closing animation.
    
     */
    get animationEnabled() {
        return this._getOption('animationEnabled');
    }
    set animationEnabled(value) {
        this._setOption('animationEnabled', value);
    }
    /**
     * Specifies whether to close the drawer if a user clicks or taps the view area.
    
     */
    get closeOnOutsideClick() {
        return this._getOption('closeOnOutsideClick');
    }
    set closeOnOutsideClick(value) {
        this._setOption('closeOnOutsideClick', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * Specifies the drawer&apos;s width or height (depending on the drawer&apos;s position) in the opened state.
    
     */
    get maxSize() {
        return this._getOption('maxSize');
    }
    set maxSize(value) {
        this._setOption('maxSize', value);
    }
    /**
     * Specifies the drawer&apos;s width or height (depending on the drawer&apos;s position) in the closed state.
    
     */
    get minSize() {
        return this._getOption('minSize');
    }
    set minSize(value) {
        this._setOption('minSize', value);
    }
    /**
     * Specifies whether the drawer is opened.
    
     */
    get opened() {
        return this._getOption('opened');
    }
    set opened(value) {
        this._setOption('opened', value);
    }
    /**
     * Specifies how the drawer interacts with the view in the opened state.
    
     */
    get openedStateMode() {
        return this._getOption('openedStateMode');
    }
    set openedStateMode(value) {
        this._setOption('openedStateMode', value);
    }
    /**
     * Specifies the drawer&apos;s position in relation to the view.
    
     */
    get position() {
        return this._getOption('position');
    }
    set position(value) {
        this._setOption('position', value);
    }
    /**
     * Specifies the drawer&apos;s reveal mode.
    
     */
    get revealMode() {
        return this._getOption('revealMode');
    }
    set revealMode(value) {
        this._setOption('revealMode', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies whether to shade the view when the drawer is opened.
    
     */
    get shading() {
        return this._getOption('shading');
    }
    set shading(value) {
        this._setOption('shading', value);
    }
    /**
     * Specifies the drawer&apos;s content.
    
     */
    get template() {
        return this._getOption('template');
    }
    set template(value) {
        this._setOption('template', value);
    }
    /**
     * Specifies whether the UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    _createInstance(element, options) {
        return new DxDrawer(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
}
/** @nocollapse */ DxDrawerComponent.ɵfac = function DxDrawerComponent_Factory(t) { return new (t || DxDrawerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxDrawerComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxDrawerComponent, selectors: [["dx-drawer"]], inputs: { activeStateEnabled: "activeStateEnabled", animationDuration: "animationDuration", animationEnabled: "animationEnabled", closeOnOutsideClick: "closeOnOutsideClick", disabled: "disabled", elementAttr: "elementAttr", height: "height", hint: "hint", hoverStateEnabled: "hoverStateEnabled", maxSize: "maxSize", minSize: "minSize", opened: "opened", openedStateMode: "openedStateMode", position: "position", revealMode: "revealMode", rtlEnabled: "rtlEnabled", shading: "shading", template: "template", visible: "visible", width: "width" }, outputs: { onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", activeStateEnabledChange: "activeStateEnabledChange", animationDurationChange: "animationDurationChange", animationEnabledChange: "animationEnabledChange", closeOnOutsideClickChange: "closeOnOutsideClickChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", heightChange: "heightChange", hintChange: "hintChange", hoverStateEnabledChange: "hoverStateEnabledChange", maxSizeChange: "maxSizeChange", minSizeChange: "minSizeChange", openedChange: "openedChange", openedStateModeChange: "openedStateModeChange", positionChange: "positionChange", revealModeChange: "revealModeChange", rtlEnabledChange: "rtlEnabledChange", shadingChange: "shadingChange", templateChange: "templateChange", visibleChange: "visibleChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost
        ]), i0.ɵɵInheritDefinitionFeature], ngContentSelectors: _c0, decls: 1, vars: 0, template: function DxDrawerComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
    } }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDrawerComponent, [{
        type: Component,
        args: [{
                selector: 'dx-drawer',
                template: '<ng-content></ng-content>',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { activeStateEnabled: [{
            type: Input
        }], animationDuration: [{
            type: Input
        }], animationEnabled: [{
            type: Input
        }], closeOnOutsideClick: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], height: [{
            type: Input
        }], hint: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], maxSize: [{
            type: Input
        }], minSize: [{
            type: Input
        }], opened: [{
            type: Input
        }], openedStateMode: [{
            type: Input
        }], position: [{
            type: Input
        }], revealMode: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], shading: [{
            type: Input
        }], template: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], activeStateEnabledChange: [{
            type: Output
        }], animationDurationChange: [{
            type: Output
        }], animationEnabledChange: [{
            type: Output
        }], closeOnOutsideClickChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], maxSizeChange: [{
            type: Output
        }], minSizeChange: [{
            type: Output
        }], openedChange: [{
            type: Output
        }], openedStateModeChange: [{
            type: Output
        }], positionChange: [{
            type: Output
        }], revealModeChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], shadingChange: [{
            type: Output
        }], templateChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }] }); })();
class DxDrawerModule {
}
/** @nocollapse */ DxDrawerModule.ɵfac = function DxDrawerModule_Factory(t) { return new (t || DxDrawerModule)(); };
/** @nocollapse */ DxDrawerModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxDrawerModule });
/** @nocollapse */ DxDrawerModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxIntegrationModule,
            DxTemplateModule
        ], DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDrawerModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxDrawerComponent
                ],
                exports: [
                    DxDrawerComponent,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxDrawerModule, { declarations: [DxDrawerComponent], imports: [DxIntegrationModule,
        DxTemplateModule], exports: [DxDrawerComponent, DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxDrawerComponent, DxDrawerModule };

