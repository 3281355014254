import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxValidationGroup from 'devextreme/ui/validation_group';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import * as i2 from '@angular/platform-browser';

const _c0 = ["*"];
/**
 * The ValidationGroup is a UI component that allows you to validate several editors simultaneously.

 */
class DxValidationGroupComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._createEventEmitters([
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { emit: 'elementAttrChange' },
            { emit: 'heightChange' },
            { emit: 'widthChange' }
        ]);
        optionHost.setHost(this);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    _createInstance(element, options) {
        return new DxValidationGroup(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
}
/** @nocollapse */ DxValidationGroupComponent.ɵfac = function DxValidationGroupComponent_Factory(t) { return new (t || DxValidationGroupComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxValidationGroupComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxValidationGroupComponent, selectors: [["dx-validation-group"]], inputs: { elementAttr: "elementAttr", height: "height", width: "width" }, outputs: { onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", elementAttrChange: "elementAttrChange", heightChange: "heightChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost
        ]), i0.ɵɵInheritDefinitionFeature], ngContentSelectors: _c0, decls: 1, vars: 0, template: function DxValidationGroupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
    } }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxValidationGroupComponent, [{
        type: Component,
        args: [{
                selector: 'dx-validation-group',
                template: '<ng-content></ng-content>',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { elementAttr: [{
            type: Input
        }], height: [{
            type: Input
        }], width: [{
            type: Input
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }] }); })();
class DxValidationGroupModule {
}
/** @nocollapse */ DxValidationGroupModule.ɵfac = function DxValidationGroupModule_Factory(t) { return new (t || DxValidationGroupModule)(); };
/** @nocollapse */ DxValidationGroupModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxValidationGroupModule });
/** @nocollapse */ DxValidationGroupModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxIntegrationModule,
            DxTemplateModule
        ], DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxValidationGroupModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxValidationGroupComponent
                ],
                exports: [
                    DxValidationGroupComponent,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxValidationGroupModule, { declarations: [DxValidationGroupComponent], imports: [DxIntegrationModule,
        DxTemplateModule], exports: [DxValidationGroupComponent, DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxValidationGroupComponent, DxValidationGroupModule };

