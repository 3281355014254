import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxResizable from 'devextreme/ui/resizable';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import * as i2 from '@angular/platform-browser';

const _c0 = ["*"];
/**
 * The Resizable UI component enables its content to be resizable in the UI.

 */
class DxResizableComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._createEventEmitters([
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'resize', emit: 'onResize' },
            { subscribe: 'resizeEnd', emit: 'onResizeEnd' },
            { subscribe: 'resizeStart', emit: 'onResizeStart' },
            { emit: 'areaChange' },
            { emit: 'elementAttrChange' },
            { emit: 'handlesChange' },
            { emit: 'heightChange' },
            { emit: 'keepAspectRatioChange' },
            { emit: 'maxHeightChange' },
            { emit: 'maxWidthChange' },
            { emit: 'minHeightChange' },
            { emit: 'minWidthChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'widthChange' }
        ]);
        optionHost.setHost(this);
    }
    /**
     * Specifies the area within which users can resize the UI component.
    
     */
    get area() {
        return this._getOption('area');
    }
    set area(value) {
        this._setOption('area', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies which borders of the UI component element are used as a handle.
    
     */
    get handles() {
        return this._getOption('handles');
    }
    set handles(value) {
        this._setOption('handles', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies whether to resize the UI component&apos;s content proportionally when you use corner handles.
    
     */
    get keepAspectRatio() {
        return this._getOption('keepAspectRatio');
    }
    set keepAspectRatio(value) {
        this._setOption('keepAspectRatio', value);
    }
    /**
     * Specifies the upper height boundary for resizing.
    
     */
    get maxHeight() {
        return this._getOption('maxHeight');
    }
    set maxHeight(value) {
        this._setOption('maxHeight', value);
    }
    /**
     * Specifies the upper width boundary for resizing.
    
     */
    get maxWidth() {
        return this._getOption('maxWidth');
    }
    set maxWidth(value) {
        this._setOption('maxWidth', value);
    }
    /**
     * Specifies the lower height boundary for resizing.
    
     */
    get minHeight() {
        return this._getOption('minHeight');
    }
    set minHeight(value) {
        this._setOption('minHeight', value);
    }
    /**
     * Specifies the lower width boundary for resizing.
    
     */
    get minWidth() {
        return this._getOption('minWidth');
    }
    set minWidth(value) {
        this._setOption('minWidth', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    _createInstance(element, options) {
        return new DxResizable(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
}
/** @nocollapse */ DxResizableComponent.ɵfac = function DxResizableComponent_Factory(t) { return new (t || DxResizableComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxResizableComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxResizableComponent, selectors: [["dx-resizable"]], inputs: { area: "area", elementAttr: "elementAttr", handles: "handles", height: "height", keepAspectRatio: "keepAspectRatio", maxHeight: "maxHeight", maxWidth: "maxWidth", minHeight: "minHeight", minWidth: "minWidth", rtlEnabled: "rtlEnabled", width: "width" }, outputs: { onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onResize: "onResize", onResizeEnd: "onResizeEnd", onResizeStart: "onResizeStart", areaChange: "areaChange", elementAttrChange: "elementAttrChange", handlesChange: "handlesChange", heightChange: "heightChange", keepAspectRatioChange: "keepAspectRatioChange", maxHeightChange: "maxHeightChange", maxWidthChange: "maxWidthChange", minHeightChange: "minHeightChange", minWidthChange: "minWidthChange", rtlEnabledChange: "rtlEnabledChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost
        ]), i0.ɵɵInheritDefinitionFeature], ngContentSelectors: _c0, decls: 1, vars: 0, template: function DxResizableComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
    } }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxResizableComponent, [{
        type: Component,
        args: [{
                selector: 'dx-resizable',
                template: '<ng-content></ng-content>',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { area: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], handles: [{
            type: Input
        }], height: [{
            type: Input
        }], keepAspectRatio: [{
            type: Input
        }], maxHeight: [{
            type: Input
        }], maxWidth: [{
            type: Input
        }], minHeight: [{
            type: Input
        }], minWidth: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], width: [{
            type: Input
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onResize: [{
            type: Output
        }], onResizeEnd: [{
            type: Output
        }], onResizeStart: [{
            type: Output
        }], areaChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], handlesChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], keepAspectRatioChange: [{
            type: Output
        }], maxHeightChange: [{
            type: Output
        }], maxWidthChange: [{
            type: Output
        }], minHeightChange: [{
            type: Output
        }], minWidthChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }] }); })();
class DxResizableModule {
}
/** @nocollapse */ DxResizableModule.ɵfac = function DxResizableModule_Factory(t) { return new (t || DxResizableModule)(); };
/** @nocollapse */ DxResizableModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxResizableModule });
/** @nocollapse */ DxResizableModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxIntegrationModule,
            DxTemplateModule
        ], DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxResizableModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxResizableComponent
                ],
                exports: [
                    DxResizableComponent,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxResizableModule, { declarations: [DxResizableComponent], imports: [DxIntegrationModule,
        DxTemplateModule], exports: [DxResizableComponent, DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxResizableComponent, DxResizableModule };

