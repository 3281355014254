import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, SkipSelf, Optional, Host, Inject, Input, Output, ContentChildren, NgModule } from '@angular/core';
import DxValidator from 'devextreme/ui/validator';
import * as i1 from 'devextreme-angular/core';
import { DxComponentExtension, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxiValidationRuleComponent, DxoAdapterModule, DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * A UI component that is used to validate the associated DevExtreme editors against the defined validation rules.

 */
class DxValidatorComponent extends DxComponentExtension {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, parentOptionHost, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this._createEventEmitters([
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'validated', emit: 'onValidated' },
            { emit: 'adapterChange' },
            { emit: 'elementAttrChange' },
            { emit: 'heightChange' },
            { emit: 'nameChange' },
            { emit: 'validationGroupChange' },
            { emit: 'validationRulesChange' },
            { emit: 'widthChange' }
        ]);
        this.parentElement = this.getParentElement(parentOptionHost);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * An object that specifies what and when to validate, and how to apply the validation result.
    
     */
    get adapter() {
        return this._getOption('adapter');
    }
    set adapter(value) {
        this._setOption('adapter', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies the editor name to be used in the validation default messages.
    
     */
    get name() {
        return this._getOption('name');
    }
    set name(value) {
        this._setOption('name', value);
    }
    /**
     * Specifies the validation group the editor will be related to.
    
     */
    get validationGroup() {
        return this._getOption('validationGroup');
    }
    set validationGroup(value) {
        this._setOption('validationGroup', value);
    }
    /**
     * An array of validation rules to be checked for the editor with which the dxValidator object is associated.
    
     */
    get validationRules() {
        return this._getOption('validationRules');
    }
    set validationRules(value) {
        this._setOption('validationRules', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    get validationRulesChildren() {
        return this._getOption('validationRules');
    }
    set validationRulesChildren(value) {
        this.setChildren('validationRules', value);
    }
    _createInstance(element, options) {
        if (this.parentElement) {
            return new DxValidator(this.parentElement, options);
        }
        return new DxValidator(element, options);
    }
    getParentElement(host) {
        if (host) {
            const parentHost = host.getHost();
            return parentHost.element.nativeElement;
        }
        return;
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('validationRules', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('validationRules');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxValidatorComponent.ɵfac = function DxValidatorComponent_Factory(t) { return new (t || DxValidatorComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost, 13), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxValidatorComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxValidatorComponent, selectors: [["dx-validator"]], contentQueries: function DxValidatorComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, DxiValidationRuleComponent, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.validationRulesChildren = _t);
    } }, inputs: { adapter: "adapter", elementAttr: "elementAttr", height: "height", name: "name", validationGroup: "validationGroup", validationRules: "validationRules", width: "width" }, outputs: { onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onValidated: "onValidated", adapterChange: "adapterChange", elementAttrChange: "elementAttrChange", heightChange: "heightChange", nameChange: "nameChange", validationGroupChange: "validationGroupChange", validationRulesChange: "validationRulesChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxValidatorComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxValidatorComponent, [{
        type: Component,
        args: [{
                selector: 'dx-validator',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost, decorators: [{
                type: SkipSelf
            }, {
                type: Optional
            }, {
                type: Host
            }] }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { adapter: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], height: [{
            type: Input
        }], name: [{
            type: Input
        }], validationGroup: [{
            type: Input
        }], validationRules: [{
            type: Input
        }], width: [{
            type: Input
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onValidated: [{
            type: Output
        }], adapterChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], nameChange: [{
            type: Output
        }], validationGroupChange: [{
            type: Output
        }], validationRulesChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }], validationRulesChildren: [{
            type: ContentChildren,
            args: [DxiValidationRuleComponent]
        }] }); })();
class DxValidatorModule {
}
/** @nocollapse */ DxValidatorModule.ɵfac = function DxValidatorModule_Factory(t) { return new (t || DxValidatorModule)(); };
/** @nocollapse */ DxValidatorModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxValidatorModule });
/** @nocollapse */ DxValidatorModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxoAdapterModule,
            DxiValidationRuleModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxoAdapterModule,
        DxiValidationRuleModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxValidatorModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxoAdapterModule,
                    DxiValidationRuleModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxValidatorComponent
                ],
                exports: [
                    DxValidatorComponent,
                    DxoAdapterModule,
                    DxiValidationRuleModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxValidatorModule, { declarations: [DxValidatorComponent], imports: [DxoAdapterModule,
        DxiValidationRuleModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxValidatorComponent, DxoAdapterModule,
        DxiValidationRuleModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxValidatorComponent, DxValidatorModule };

