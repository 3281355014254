import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DevExtremeModule } from 'devextreme-angular';
// import { NullWithDefaultPipe } from '../pipe/null-with-default.pipe';

@NgModule({
  imports: [DevExtremeModule, RouterModule, CommonModule],
  declarations: [],
  exports: [],
})
export class SharedModule {}
