import {
  HttpContextToken,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { AuthService } from '../../services/auth/auth.service';

export const BYPASS_TOKEN = new HttpContextToken(() => false);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private jwtHelper = new JwtHelperService();

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    const baseUrl = environment.apiURL;

    let modifiedReq = req.clone({
      url: `${baseUrl}/${req.url}`,
    });
    if (req.context.get(BYPASS_TOKEN)) {
      return next.handle(modifiedReq);
    }

    const userToken = this.localStorageService.getToken();
    if (!userToken) {
      this.router.navigateByUrl('/auth/login');
      return;
    }

    const isExpired = this.jwtHelper.isTokenExpired(userToken);
    if (isExpired) {
      this.authService.logout();
      this.router.navigateByUrl('/auth/login');
      return;
    }

    modifiedReq = modifiedReq.clone({
      headers: req.headers.set('Authorization', `Bearer ${userToken}`),
      url: `${baseUrl}/${req.url}`,
    });
    return next.handle(modifiedReq);
  }
}
