import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxLoadIndicator from 'devextreme/ui/load_indicator';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import * as i2 from '@angular/platform-browser';

/**
 * The LoadIndicator is a UI element notifying the viewer that a process is in progress.

 */
class DxLoadIndicatorComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._createEventEmitters([
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { emit: 'elementAttrChange' },
            { emit: 'heightChange' },
            { emit: 'hintChange' },
            { emit: 'indicatorSrcChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' }
        ]);
        optionHost.setHost(this);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies the path to an image used as the indicator.
    
     */
    get indicatorSrc() {
        return this._getOption('indicatorSrc');
    }
    set indicatorSrc(value) {
        this._setOption('indicatorSrc', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies whether the UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    _createInstance(element, options) {
        return new DxLoadIndicator(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
}
/** @nocollapse */ DxLoadIndicatorComponent.ɵfac = function DxLoadIndicatorComponent_Factory(t) { return new (t || DxLoadIndicatorComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxLoadIndicatorComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxLoadIndicatorComponent, selectors: [["dx-load-indicator"]], inputs: { elementAttr: "elementAttr", height: "height", hint: "hint", indicatorSrc: "indicatorSrc", rtlEnabled: "rtlEnabled", visible: "visible", width: "width" }, outputs: { onContentReady: "onContentReady", onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", elementAttrChange: "elementAttrChange", heightChange: "heightChange", hintChange: "hintChange", indicatorSrcChange: "indicatorSrcChange", rtlEnabledChange: "rtlEnabledChange", visibleChange: "visibleChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost
        ]), i0.ɵɵInheritDefinitionFeature], decls: 0, vars: 0, template: function DxLoadIndicatorComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxLoadIndicatorComponent, [{
        type: Component,
        args: [{
                selector: 'dx-load-indicator',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { elementAttr: [{
            type: Input
        }], height: [{
            type: Input
        }], hint: [{
            type: Input
        }], indicatorSrc: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], onContentReady: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], indicatorSrcChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }] }); })();
class DxLoadIndicatorModule {
}
/** @nocollapse */ DxLoadIndicatorModule.ɵfac = function DxLoadIndicatorModule_Factory(t) { return new (t || DxLoadIndicatorModule)(); };
/** @nocollapse */ DxLoadIndicatorModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxLoadIndicatorModule });
/** @nocollapse */ DxLoadIndicatorModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxIntegrationModule,
            DxTemplateModule
        ], DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxLoadIndicatorModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxLoadIndicatorComponent
                ],
                exports: [
                    DxLoadIndicatorComponent,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxLoadIndicatorModule, { declarations: [DxLoadIndicatorComponent], imports: [DxIntegrationModule,
        DxTemplateModule], exports: [DxLoadIndicatorComponent, DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxLoadIndicatorComponent, DxLoadIndicatorModule };

