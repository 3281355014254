import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxScrollView from 'devextreme/ui/scroll_view';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import * as i2 from '@angular/platform-browser';

const _c0 = ["*"];
/**
 * The ScrollView is a UI component that enables a user to scroll its content.

 */
class DxScrollViewComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._createEventEmitters([
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'pullDown', emit: 'onPullDown' },
            { subscribe: 'reachBottom', emit: 'onReachBottom' },
            { subscribe: 'scroll', emit: 'onScroll' },
            { subscribe: 'updated', emit: 'onUpdated' },
            { emit: 'bounceEnabledChange' },
            { emit: 'directionChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'heightChange' },
            { emit: 'pulledDownTextChange' },
            { emit: 'pullingDownTextChange' },
            { emit: 'reachBottomTextChange' },
            { emit: 'refreshingTextChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'scrollByContentChange' },
            { emit: 'scrollByThumbChange' },
            { emit: 'showScrollbarChange' },
            { emit: 'useNativeChange' },
            { emit: 'widthChange' }
        ]);
        optionHost.setHost(this);
    }
    /**
     * A Boolean value specifying whether to enable or disable the bounce-back effect.
    
     */
    get bounceEnabled() {
        return this._getOption('bounceEnabled');
    }
    set bounceEnabled(value) {
        this._setOption('bounceEnabled', value);
    }
    /**
     * A string value specifying the available scrolling directions.
    
     */
    get direction() {
        return this._getOption('direction');
    }
    set direction(value) {
        this._setOption('direction', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies the text shown in the pullDown panel when pulling the content down lowers the refresh threshold.
    
     */
    get pulledDownText() {
        return this._getOption('pulledDownText');
    }
    set pulledDownText(value) {
        this._setOption('pulledDownText', value);
    }
    /**
     * Specifies the text shown in the pullDown panel while pulling the content down to the refresh threshold.
    
     */
    get pullingDownText() {
        return this._getOption('pullingDownText');
    }
    set pullingDownText(value) {
        this._setOption('pullingDownText', value);
    }
    /**
     * Specifies the text shown in the pullDown panel displayed when content is scrolled to the bottom.
    
     */
    get reachBottomText() {
        return this._getOption('reachBottomText');
    }
    set reachBottomText(value) {
        this._setOption('reachBottomText', value);
    }
    /**
     * Specifies the text shown in the pullDown panel displayed when the content is being refreshed.
    
     */
    get refreshingText() {
        return this._getOption('refreshingText');
    }
    set refreshingText(value) {
        this._setOption('refreshingText', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * A Boolean value specifying whether or not an end user can scroll the UI component content swiping it up or down. Applies only if useNative is false
    
     */
    get scrollByContent() {
        return this._getOption('scrollByContent');
    }
    set scrollByContent(value) {
        this._setOption('scrollByContent', value);
    }
    /**
     * Specifies whether a user can scroll the content with the scrollbar. Applies only if useNative is false.
    
     */
    get scrollByThumb() {
        return this._getOption('scrollByThumb');
    }
    set scrollByThumb(value) {
        this._setOption('scrollByThumb', value);
    }
    /**
     * Specifies when the UI component shows the scrollbar.
    
     */
    get showScrollbar() {
        return this._getOption('showScrollbar');
    }
    set showScrollbar(value) {
        this._setOption('showScrollbar', value);
    }
    /**
     * Indicates whether to use native or simulated scrolling.
    
     */
    get useNative() {
        return this._getOption('useNative');
    }
    set useNative(value) {
        this._setOption('useNative', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    _createInstance(element, options) {
        return new DxScrollView(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
}
/** @nocollapse */ DxScrollViewComponent.ɵfac = function DxScrollViewComponent_Factory(t) { return new (t || DxScrollViewComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxScrollViewComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxScrollViewComponent, selectors: [["dx-scroll-view"]], inputs: { bounceEnabled: "bounceEnabled", direction: "direction", disabled: "disabled", elementAttr: "elementAttr", height: "height", pulledDownText: "pulledDownText", pullingDownText: "pullingDownText", reachBottomText: "reachBottomText", refreshingText: "refreshingText", rtlEnabled: "rtlEnabled", scrollByContent: "scrollByContent", scrollByThumb: "scrollByThumb", showScrollbar: "showScrollbar", useNative: "useNative", width: "width" }, outputs: { onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onPullDown: "onPullDown", onReachBottom: "onReachBottom", onScroll: "onScroll", onUpdated: "onUpdated", bounceEnabledChange: "bounceEnabledChange", directionChange: "directionChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", heightChange: "heightChange", pulledDownTextChange: "pulledDownTextChange", pullingDownTextChange: "pullingDownTextChange", reachBottomTextChange: "reachBottomTextChange", refreshingTextChange: "refreshingTextChange", rtlEnabledChange: "rtlEnabledChange", scrollByContentChange: "scrollByContentChange", scrollByThumbChange: "scrollByThumbChange", showScrollbarChange: "showScrollbarChange", useNativeChange: "useNativeChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost
        ]), i0.ɵɵInheritDefinitionFeature], ngContentSelectors: _c0, decls: 1, vars: 0, template: function DxScrollViewComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
    } }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxScrollViewComponent, [{
        type: Component,
        args: [{
                selector: 'dx-scroll-view',
                template: '<ng-content></ng-content>',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { bounceEnabled: [{
            type: Input
        }], direction: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], height: [{
            type: Input
        }], pulledDownText: [{
            type: Input
        }], pullingDownText: [{
            type: Input
        }], reachBottomText: [{
            type: Input
        }], refreshingText: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], scrollByContent: [{
            type: Input
        }], scrollByThumb: [{
            type: Input
        }], showScrollbar: [{
            type: Input
        }], useNative: [{
            type: Input
        }], width: [{
            type: Input
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onPullDown: [{
            type: Output
        }], onReachBottom: [{
            type: Output
        }], onScroll: [{
            type: Output
        }], onUpdated: [{
            type: Output
        }], bounceEnabledChange: [{
            type: Output
        }], directionChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], pulledDownTextChange: [{
            type: Output
        }], pullingDownTextChange: [{
            type: Output
        }], reachBottomTextChange: [{
            type: Output
        }], refreshingTextChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], scrollByContentChange: [{
            type: Output
        }], scrollByThumbChange: [{
            type: Output
        }], showScrollbarChange: [{
            type: Output
        }], useNativeChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }] }); })();
class DxScrollViewModule {
}
/** @nocollapse */ DxScrollViewModule.ɵfac = function DxScrollViewModule_Factory(t) { return new (t || DxScrollViewModule)(); };
/** @nocollapse */ DxScrollViewModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxScrollViewModule });
/** @nocollapse */ DxScrollViewModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxIntegrationModule,
            DxTemplateModule
        ], DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxScrollViewModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxScrollViewComponent
                ],
                exports: [
                    DxScrollViewComponent,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxScrollViewModule, { declarations: [DxScrollViewComponent], imports: [DxIntegrationModule,
        DxTemplateModule], exports: [DxScrollViewComponent, DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxScrollViewComponent, DxScrollViewModule };

