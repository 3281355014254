import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxDeferRendering from 'devextreme/ui/defer_rendering';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxoAnimationModule, DxoFromModule, DxoPositionModule, DxoAtModule, DxoBoundaryOffsetModule, DxoCollisionModule, DxoMyModule, DxoOffsetModule, DxoToModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * The DeferRendering is a UI component that waits for its content to be ready before rendering it. While the content is getting ready, the DeferRendering displays a loading indicator.

 */
class DxDeferRenderingComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._createEventEmitters([
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'rendered', emit: 'onRendered' },
            { subscribe: 'shown', emit: 'onShown' },
            { emit: 'accessKeyChange' },
            { emit: 'activeStateEnabledChange' },
            { emit: 'animationChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'focusStateEnabledChange' },
            { emit: 'heightChange' },
            { emit: 'hintChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'renderWhenChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'showLoadIndicatorChange' },
            { emit: 'staggerItemSelectorChange' },
            { emit: 'tabIndexChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' }
        ]);
        optionHost.setHost(this);
    }
    /**
     * Specifies the shortcut key that sets focus on the UI component.
    
     */
    get accessKey() {
        return this._getOption('accessKey');
    }
    set accessKey(value) {
        this._setOption('accessKey', value);
    }
    /**
     * Specifies whether the UI component changes its visual state as a result of user interaction.
    
     */
    get activeStateEnabled() {
        return this._getOption('activeStateEnabled');
    }
    set activeStateEnabled(value) {
        this._setOption('activeStateEnabled', value);
    }
    /**
     * Specifies the animation to be used to show the rendered content.
    
     */
    get animation() {
        return this._getOption('animation');
    }
    set animation(value) {
        this._setOption('animation', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies whether the UI component can be focused using keyboard navigation.
    
     */
    get focusStateEnabled() {
        return this._getOption('focusStateEnabled');
    }
    set focusStateEnabled(value) {
        this._setOption('focusStateEnabled', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * Specifies when the UI component content is rendered.
    
     */
    get renderWhen() {
        return this._getOption('renderWhen');
    }
    set renderWhen(value) {
        this._setOption('renderWhen', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Indicates if a load indicator should be shown until the UI component&apos;s content is rendered.
    
     */
    get showLoadIndicator() {
        return this._getOption('showLoadIndicator');
    }
    set showLoadIndicator(value) {
        this._setOption('showLoadIndicator', value);
    }
    /**
     * Specifies a jQuery selector of items that should be rendered using a staggered animation.
    
     */
    get staggerItemSelector() {
        return this._getOption('staggerItemSelector');
    }
    set staggerItemSelector(value) {
        this._setOption('staggerItemSelector', value);
    }
    /**
     * Specifies the number of the element when the Tab key is used for navigating.
    
     */
    get tabIndex() {
        return this._getOption('tabIndex');
    }
    set tabIndex(value) {
        this._setOption('tabIndex', value);
    }
    /**
     * Specifies whether the UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    _createInstance(element, options) {
        return new DxDeferRendering(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
}
/** @nocollapse */ DxDeferRenderingComponent.ɵfac = function DxDeferRenderingComponent_Factory(t) { return new (t || DxDeferRenderingComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxDeferRenderingComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxDeferRenderingComponent, selectors: [["dx-defer-rendering"]], inputs: { accessKey: "accessKey", activeStateEnabled: "activeStateEnabled", animation: "animation", disabled: "disabled", elementAttr: "elementAttr", focusStateEnabled: "focusStateEnabled", height: "height", hint: "hint", hoverStateEnabled: "hoverStateEnabled", renderWhen: "renderWhen", rtlEnabled: "rtlEnabled", showLoadIndicator: "showLoadIndicator", staggerItemSelector: "staggerItemSelector", tabIndex: "tabIndex", visible: "visible", width: "width" }, outputs: { onContentReady: "onContentReady", onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onRendered: "onRendered", onShown: "onShown", accessKeyChange: "accessKeyChange", activeStateEnabledChange: "activeStateEnabledChange", animationChange: "animationChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", focusStateEnabledChange: "focusStateEnabledChange", heightChange: "heightChange", hintChange: "hintChange", hoverStateEnabledChange: "hoverStateEnabledChange", renderWhenChange: "renderWhenChange", rtlEnabledChange: "rtlEnabledChange", showLoadIndicatorChange: "showLoadIndicatorChange", staggerItemSelectorChange: "staggerItemSelectorChange", tabIndexChange: "tabIndexChange", visibleChange: "visibleChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost
        ]), i0.ɵɵInheritDefinitionFeature], decls: 0, vars: 0, template: function DxDeferRenderingComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDeferRenderingComponent, [{
        type: Component,
        args: [{
                selector: 'dx-defer-rendering',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { accessKey: [{
            type: Input
        }], activeStateEnabled: [{
            type: Input
        }], animation: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], focusStateEnabled: [{
            type: Input
        }], height: [{
            type: Input
        }], hint: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], renderWhen: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], showLoadIndicator: [{
            type: Input
        }], staggerItemSelector: [{
            type: Input
        }], tabIndex: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], onContentReady: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onRendered: [{
            type: Output
        }], onShown: [{
            type: Output
        }], accessKeyChange: [{
            type: Output
        }], activeStateEnabledChange: [{
            type: Output
        }], animationChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], focusStateEnabledChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], renderWhenChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], showLoadIndicatorChange: [{
            type: Output
        }], staggerItemSelectorChange: [{
            type: Output
        }], tabIndexChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }] }); })();
class DxDeferRenderingModule {
}
/** @nocollapse */ DxDeferRenderingModule.ɵfac = function DxDeferRenderingModule_Factory(t) { return new (t || DxDeferRenderingModule)(); };
/** @nocollapse */ DxDeferRenderingModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxDeferRenderingModule });
/** @nocollapse */ DxDeferRenderingModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxoAnimationModule,
            DxoFromModule,
            DxoPositionModule,
            DxoAtModule,
            DxoBoundaryOffsetModule,
            DxoCollisionModule,
            DxoMyModule,
            DxoOffsetModule,
            DxoToModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxoAnimationModule,
        DxoFromModule,
        DxoPositionModule,
        DxoAtModule,
        DxoBoundaryOffsetModule,
        DxoCollisionModule,
        DxoMyModule,
        DxoOffsetModule,
        DxoToModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxDeferRenderingModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxoAnimationModule,
                    DxoFromModule,
                    DxoPositionModule,
                    DxoAtModule,
                    DxoBoundaryOffsetModule,
                    DxoCollisionModule,
                    DxoMyModule,
                    DxoOffsetModule,
                    DxoToModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxDeferRenderingComponent
                ],
                exports: [
                    DxDeferRenderingComponent,
                    DxoAnimationModule,
                    DxoFromModule,
                    DxoPositionModule,
                    DxoAtModule,
                    DxoBoundaryOffsetModule,
                    DxoCollisionModule,
                    DxoMyModule,
                    DxoOffsetModule,
                    DxoToModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxDeferRenderingModule, { declarations: [DxDeferRenderingComponent], imports: [DxoAnimationModule,
        DxoFromModule,
        DxoPositionModule,
        DxoAtModule,
        DxoBoundaryOffsetModule,
        DxoCollisionModule,
        DxoMyModule,
        DxoOffsetModule,
        DxoToModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxDeferRenderingComponent, DxoAnimationModule,
        DxoFromModule,
        DxoPositionModule,
        DxoAtModule,
        DxoBoundaryOffsetModule,
        DxoCollisionModule,
        DxoMyModule,
        DxoOffsetModule,
        DxoToModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxDeferRenderingComponent, DxDeferRenderingModule };

