import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, ContentChildren, NgModule } from '@angular/core';
import DxActionSheet from 'devextreme/ui/action_sheet';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxiItemComponent, DxiItemModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * The ActionSheet UI component is a sheet containing a set of buttons located one under the other. These buttons usually represent several choices relating to a single task.

 */
class DxActionSheetComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this._createEventEmitters([
            { subscribe: 'cancelClick', emit: 'onCancelClick' },
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'itemClick', emit: 'onItemClick' },
            { subscribe: 'itemContextMenu', emit: 'onItemContextMenu' },
            { subscribe: 'itemHold', emit: 'onItemHold' },
            { subscribe: 'itemRendered', emit: 'onItemRendered' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { emit: 'cancelTextChange' },
            { emit: 'dataSourceChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'heightChange' },
            { emit: 'hintChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'itemHoldTimeoutChange' },
            { emit: 'itemsChange' },
            { emit: 'itemTemplateChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'showCancelButtonChange' },
            { emit: 'showTitleChange' },
            { emit: 'targetChange' },
            { emit: 'titleChange' },
            { emit: 'usePopoverChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * The text displayed in the button that closes the action sheet.
    
     */
    get cancelText() {
        return this._getOption('cancelText');
    }
    set cancelText(value) {
        this._setOption('cancelText', value);
    }
    /**
     * Binds the UI component to data.
    
     */
    get dataSource() {
        return this._getOption('dataSource');
    }
    set dataSource(value) {
        this._setOption('dataSource', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * The time period in milliseconds before the onItemHold event is raised.
    
     */
    get itemHoldTimeout() {
        return this._getOption('itemHoldTimeout');
    }
    set itemHoldTimeout(value) {
        this._setOption('itemHoldTimeout', value);
    }
    /**
     * An array of items displayed by the UI component.
    
     */
    get items() {
        return this._getOption('items');
    }
    set items(value) {
        this._setOption('items', value);
    }
    /**
     * Specifies a custom template for items.
    
     */
    get itemTemplate() {
        return this._getOption('itemTemplate');
    }
    set itemTemplate(value) {
        this._setOption('itemTemplate', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies whether or not to display the Cancel button in action sheet.
    
     */
    get showCancelButton() {
        return this._getOption('showCancelButton');
    }
    set showCancelButton(value) {
        this._setOption('showCancelButton', value);
    }
    /**
     * A Boolean value specifying whether or not the title of the action sheet is visible.
    
     */
    get showTitle() {
        return this._getOption('showTitle');
    }
    set showTitle(value) {
        this._setOption('showTitle', value);
    }
    /**
     * Specifies the element the action sheet popover points at. Applies only if usePopover is true.
    
     */
    get target() {
        return this._getOption('target');
    }
    set target(value) {
        this._setOption('target', value);
    }
    /**
     * The title of the action sheet.
    
     */
    get title() {
        return this._getOption('title');
    }
    set title(value) {
        this._setOption('title', value);
    }
    /**
     * Specifies whether or not to show the action sheet within a Popover UI component.
    
     */
    get usePopover() {
        return this._getOption('usePopover');
    }
    set usePopover(value) {
        this._setOption('usePopover', value);
    }
    /**
     * A Boolean value specifying whether or not the ActionSheet UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    get itemsChildren() {
        return this._getOption('items');
    }
    set itemsChildren(value) {
        this.setChildren('items', value);
    }
    _createInstance(element, options) {
        return new DxActionSheet(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('dataSource', changes);
        this.setupChanges('items', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('dataSource');
        this._idh.doCheck('items');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxActionSheetComponent.ɵfac = function DxActionSheetComponent_Factory(t) { return new (t || DxActionSheetComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxActionSheetComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxActionSheetComponent, selectors: [["dx-action-sheet"]], contentQueries: function DxActionSheetComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, DxiItemComponent, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemsChildren = _t);
    } }, inputs: { cancelText: "cancelText", dataSource: "dataSource", disabled: "disabled", elementAttr: "elementAttr", height: "height", hint: "hint", hoverStateEnabled: "hoverStateEnabled", itemHoldTimeout: "itemHoldTimeout", items: "items", itemTemplate: "itemTemplate", rtlEnabled: "rtlEnabled", showCancelButton: "showCancelButton", showTitle: "showTitle", target: "target", title: "title", usePopover: "usePopover", visible: "visible", width: "width" }, outputs: { onCancelClick: "onCancelClick", onContentReady: "onContentReady", onDisposing: "onDisposing", onInitialized: "onInitialized", onItemClick: "onItemClick", onItemContextMenu: "onItemContextMenu", onItemHold: "onItemHold", onItemRendered: "onItemRendered", onOptionChanged: "onOptionChanged", cancelTextChange: "cancelTextChange", dataSourceChange: "dataSourceChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", heightChange: "heightChange", hintChange: "hintChange", hoverStateEnabledChange: "hoverStateEnabledChange", itemHoldTimeoutChange: "itemHoldTimeoutChange", itemsChange: "itemsChange", itemTemplateChange: "itemTemplateChange", rtlEnabledChange: "rtlEnabledChange", showCancelButtonChange: "showCancelButtonChange", showTitleChange: "showTitleChange", targetChange: "targetChange", titleChange: "titleChange", usePopoverChange: "usePopoverChange", visibleChange: "visibleChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxActionSheetComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxActionSheetComponent, [{
        type: Component,
        args: [{
                selector: 'dx-action-sheet',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { cancelText: [{
            type: Input
        }], dataSource: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], height: [{
            type: Input
        }], hint: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], itemHoldTimeout: [{
            type: Input
        }], items: [{
            type: Input
        }], itemTemplate: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], showCancelButton: [{
            type: Input
        }], showTitle: [{
            type: Input
        }], target: [{
            type: Input
        }], title: [{
            type: Input
        }], usePopover: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], onCancelClick: [{
            type: Output
        }], onContentReady: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onItemClick: [{
            type: Output
        }], onItemContextMenu: [{
            type: Output
        }], onItemHold: [{
            type: Output
        }], onItemRendered: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], cancelTextChange: [{
            type: Output
        }], dataSourceChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], itemHoldTimeoutChange: [{
            type: Output
        }], itemsChange: [{
            type: Output
        }], itemTemplateChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], showCancelButtonChange: [{
            type: Output
        }], showTitleChange: [{
            type: Output
        }], targetChange: [{
            type: Output
        }], titleChange: [{
            type: Output
        }], usePopoverChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }], itemsChildren: [{
            type: ContentChildren,
            args: [DxiItemComponent]
        }] }); })();
class DxActionSheetModule {
}
/** @nocollapse */ DxActionSheetModule.ɵfac = function DxActionSheetModule_Factory(t) { return new (t || DxActionSheetModule)(); };
/** @nocollapse */ DxActionSheetModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxActionSheetModule });
/** @nocollapse */ DxActionSheetModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxiItemModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxiItemModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxActionSheetModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxiItemModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxActionSheetComponent
                ],
                exports: [
                    DxActionSheetComponent,
                    DxiItemModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxActionSheetModule, { declarations: [DxActionSheetComponent], imports: [DxiItemModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxActionSheetComponent, DxiItemModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxActionSheetComponent, DxActionSheetModule };

