import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxSpeedDialAction from 'devextreme/ui/speed_dial_action';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import * as i2 from '@angular/platform-browser';

/**
 * The SpeedDialAction is a button that performs a custom action. It can be represented by a Floating Action Button (FAB) or a button in a speed dial menu opened with the FAB.

 */
class DxSpeedDialActionComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._createEventEmitters([
            { subscribe: 'click', emit: 'onClick' },
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { emit: 'accessKeyChange' },
            { emit: 'activeStateEnabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'focusStateEnabledChange' },
            { emit: 'hintChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'iconChange' },
            { emit: 'indexChange' },
            { emit: 'labelChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'tabIndexChange' },
            { emit: 'visibleChange' }
        ]);
        optionHost.setHost(this);
    }
    /**
     * Specifies the shortcut key that sets focus on the UI component.
    
     */
    get accessKey() {
        return this._getOption('accessKey');
    }
    set accessKey(value) {
        this._setOption('accessKey', value);
    }
    /**
     * Specifies whether the UI component changes its visual state as a result of user interaction.
    
     */
    get activeStateEnabled() {
        return this._getOption('activeStateEnabled');
    }
    set activeStateEnabled(value) {
        this._setOption('activeStateEnabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies whether the UI component can be focused using keyboard navigation.
    
     */
    get focusStateEnabled() {
        return this._getOption('focusStateEnabled');
    }
    set focusStateEnabled(value) {
        this._setOption('focusStateEnabled', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * Specifies the icon the FAB or speed dial action button displays.
    
     */
    get icon() {
        return this._getOption('icon');
    }
    set icon(value) {
        this._setOption('icon', value);
    }
    /**
     * Allows you to reorder action buttons in the speed dial menu.
    
     */
    get index() {
        return this._getOption('index');
    }
    set index(value) {
        this._setOption('index', value);
    }
    /**
     * Specifies the text label displayed inside the FAB or near the speed dial action button.
    
     */
    get label() {
        return this._getOption('label');
    }
    set label(value) {
        this._setOption('label', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies the number of the element when the Tab key is used for navigating.
    
     */
    get tabIndex() {
        return this._getOption('tabIndex');
    }
    set tabIndex(value) {
        this._setOption('tabIndex', value);
    }
    /**
     * Allows you to hide the FAB from the view or the action from the speed dial menu.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    _createInstance(element, options) {
        return new DxSpeedDialAction(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
}
/** @nocollapse */ DxSpeedDialActionComponent.ɵfac = function DxSpeedDialActionComponent_Factory(t) { return new (t || DxSpeedDialActionComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxSpeedDialActionComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxSpeedDialActionComponent, selectors: [["dx-speed-dial-action"]], inputs: { accessKey: "accessKey", activeStateEnabled: "activeStateEnabled", elementAttr: "elementAttr", focusStateEnabled: "focusStateEnabled", hint: "hint", hoverStateEnabled: "hoverStateEnabled", icon: "icon", index: "index", label: "label", rtlEnabled: "rtlEnabled", tabIndex: "tabIndex", visible: "visible" }, outputs: { onClick: "onClick", onContentReady: "onContentReady", onDisposing: "onDisposing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", accessKeyChange: "accessKeyChange", activeStateEnabledChange: "activeStateEnabledChange", elementAttrChange: "elementAttrChange", focusStateEnabledChange: "focusStateEnabledChange", hintChange: "hintChange", hoverStateEnabledChange: "hoverStateEnabledChange", iconChange: "iconChange", indexChange: "indexChange", labelChange: "labelChange", rtlEnabledChange: "rtlEnabledChange", tabIndexChange: "tabIndexChange", visibleChange: "visibleChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost
        ]), i0.ɵɵInheritDefinitionFeature], decls: 0, vars: 0, template: function DxSpeedDialActionComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSpeedDialActionComponent, [{
        type: Component,
        args: [{
                selector: 'dx-speed-dial-action',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { accessKey: [{
            type: Input
        }], activeStateEnabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], focusStateEnabled: [{
            type: Input
        }], hint: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], icon: [{
            type: Input
        }], index: [{
            type: Input
        }], label: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], tabIndex: [{
            type: Input
        }], visible: [{
            type: Input
        }], onClick: [{
            type: Output
        }], onContentReady: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], accessKeyChange: [{
            type: Output
        }], activeStateEnabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], focusStateEnabledChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], iconChange: [{
            type: Output
        }], indexChange: [{
            type: Output
        }], labelChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], tabIndexChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }] }); })();
class DxSpeedDialActionModule {
}
/** @nocollapse */ DxSpeedDialActionModule.ɵfac = function DxSpeedDialActionModule_Factory(t) { return new (t || DxSpeedDialActionModule)(); };
/** @nocollapse */ DxSpeedDialActionModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxSpeedDialActionModule });
/** @nocollapse */ DxSpeedDialActionModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxIntegrationModule,
            DxTemplateModule
        ], DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxSpeedDialActionModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxSpeedDialActionComponent
                ],
                exports: [
                    DxSpeedDialActionComponent,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxSpeedDialActionModule, { declarations: [DxSpeedDialActionComponent], imports: [DxIntegrationModule,
        DxTemplateModule], exports: [DxSpeedDialActionComponent, DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxSpeedDialActionComponent, DxSpeedDialActionModule };

