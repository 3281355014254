import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, ContentChildren, NgModule } from '@angular/core';
import DxValidationSummary from 'devextreme/ui/validation_summary';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxiItemComponent, DxiItemModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * A UI component for displaying the result of checking validation rules for editors.

 */
class DxValidationSummaryComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this._createEventEmitters([
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'itemClick', emit: 'onItemClick' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { emit: 'elementAttrChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'itemsChange' },
            { emit: 'itemTemplateChange' },
            { emit: 'validationGroupChange' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * An array of items displayed by the UI component.
    
     */
    get items() {
        return this._getOption('items');
    }
    set items(value) {
        this._setOption('items', value);
    }
    /**
     * Specifies a custom template for items.
    
     */
    get itemTemplate() {
        return this._getOption('itemTemplate');
    }
    set itemTemplate(value) {
        this._setOption('itemTemplate', value);
    }
    /**
     * Specifies the validation group for which summary should be generated.
    
     */
    get validationGroup() {
        return this._getOption('validationGroup');
    }
    set validationGroup(value) {
        this._setOption('validationGroup', value);
    }
    get itemsChildren() {
        return this._getOption('items');
    }
    set itemsChildren(value) {
        this.setChildren('items', value);
    }
    _createInstance(element, options) {
        return new DxValidationSummary(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('items', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('items');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxValidationSummaryComponent.ɵfac = function DxValidationSummaryComponent_Factory(t) { return new (t || DxValidationSummaryComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxValidationSummaryComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxValidationSummaryComponent, selectors: [["dx-validation-summary"]], contentQueries: function DxValidationSummaryComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, DxiItemComponent, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemsChildren = _t);
    } }, inputs: { elementAttr: "elementAttr", hoverStateEnabled: "hoverStateEnabled", items: "items", itemTemplate: "itemTemplate", validationGroup: "validationGroup" }, outputs: { onContentReady: "onContentReady", onDisposing: "onDisposing", onInitialized: "onInitialized", onItemClick: "onItemClick", onOptionChanged: "onOptionChanged", elementAttrChange: "elementAttrChange", hoverStateEnabledChange: "hoverStateEnabledChange", itemsChange: "itemsChange", itemTemplateChange: "itemTemplateChange", validationGroupChange: "validationGroupChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxValidationSummaryComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxValidationSummaryComponent, [{
        type: Component,
        args: [{
                selector: 'dx-validation-summary',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { elementAttr: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], items: [{
            type: Input
        }], itemTemplate: [{
            type: Input
        }], validationGroup: [{
            type: Input
        }], onContentReady: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onItemClick: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], itemsChange: [{
            type: Output
        }], itemTemplateChange: [{
            type: Output
        }], validationGroupChange: [{
            type: Output
        }], itemsChildren: [{
            type: ContentChildren,
            args: [DxiItemComponent]
        }] }); })();
class DxValidationSummaryModule {
}
/** @nocollapse */ DxValidationSummaryModule.ɵfac = function DxValidationSummaryModule_Factory(t) { return new (t || DxValidationSummaryModule)(); };
/** @nocollapse */ DxValidationSummaryModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxValidationSummaryModule });
/** @nocollapse */ DxValidationSummaryModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxiItemModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxiItemModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxValidationSummaryModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxiItemModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxValidationSummaryComponent
                ],
                exports: [
                    DxValidationSummaryComponent,
                    DxiItemModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxValidationSummaryModule, { declarations: [DxValidationSummaryComponent], imports: [DxiItemModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxValidationSummaryComponent, DxiItemModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxValidationSummaryComponent, DxValidationSummaryModule };

