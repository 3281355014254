import * as i0 from '@angular/core';
import { forwardRef, PLATFORM_ID, Component, Inject, Input, Output, HostListener, ContentChildren, NgModule } from '@angular/core';
import DxFilterBuilder from 'devextreme/ui/filter_builder';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxiCustomOperationComponent, DxiFieldComponent, DxiCustomOperationModule, DxiFieldModule, DxoFormatModule, DxoLookupModule, DxoFilterOperationDescriptionsModule, DxoGroupOperationDescriptionsModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

const CUSTOM_VALUE_ACCESSOR_PROVIDER = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DxFilterBuilderComponent),
    multi: true
};
/**
 * The FilterBuilder UI component allows a user to build complex filter expressions with an unlimited number of filter conditions, combined by logical operations using the UI.

 */
class DxFilterBuilderComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this.touched = (_) => { };
        this._createEventEmitters([
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'editorPrepared', emit: 'onEditorPrepared' },
            { subscribe: 'editorPreparing', emit: 'onEditorPreparing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'valueChanged', emit: 'onValueChanged' },
            { emit: 'accessKeyChange' },
            { emit: 'activeStateEnabledChange' },
            { emit: 'allowHierarchicalFieldsChange' },
            { emit: 'customOperationsChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'fieldsChange' },
            { emit: 'filterOperationDescriptionsChange' },
            { emit: 'focusStateEnabledChange' },
            { emit: 'groupOperationDescriptionsChange' },
            { emit: 'groupOperationsChange' },
            { emit: 'heightChange' },
            { emit: 'hintChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'maxGroupLevelChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'tabIndexChange' },
            { emit: 'valueChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' },
            { emit: 'onBlur' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * Specifies the shortcut key that sets focus on the UI component.
    
     */
    get accessKey() {
        return this._getOption('accessKey');
    }
    set accessKey(value) {
        this._setOption('accessKey', value);
    }
    /**
     * Specifies whether the UI component changes its visual state as a result of user interaction.
    
     */
    get activeStateEnabled() {
        return this._getOption('activeStateEnabled');
    }
    set activeStateEnabled(value) {
        this._setOption('activeStateEnabled', value);
    }
    /**
     * Specifies whether the UI component can display hierarchical data fields.
    
     */
    get allowHierarchicalFields() {
        return this._getOption('allowHierarchicalFields');
    }
    set allowHierarchicalFields(value) {
        this._setOption('allowHierarchicalFields', value);
    }
    /**
     * Configures custom filter operations.
    
     */
    get customOperations() {
        return this._getOption('customOperations');
    }
    set customOperations(value) {
        this._setOption('customOperations', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Configures fields.
    
     */
    get fields() {
        return this._getOption('fields');
    }
    set fields(value) {
        this._setOption('fields', value);
    }
    /**
     * Specifies filter operation descriptions.
    
     */
    get filterOperationDescriptions() {
        return this._getOption('filterOperationDescriptions');
    }
    set filterOperationDescriptions(value) {
        this._setOption('filterOperationDescriptions', value);
    }
    /**
     * Specifies whether the UI component can be focused using keyboard navigation.
    
     */
    get focusStateEnabled() {
        return this._getOption('focusStateEnabled');
    }
    set focusStateEnabled(value) {
        this._setOption('focusStateEnabled', value);
    }
    /**
     * Specifies group operation descriptions.
    
     */
    get groupOperationDescriptions() {
        return this._getOption('groupOperationDescriptions');
    }
    set groupOperationDescriptions(value) {
        this._setOption('groupOperationDescriptions', value);
    }
    /**
     * Specifies a set of available group operations.
    
     */
    get groupOperations() {
        return this._getOption('groupOperations');
    }
    set groupOperations(value) {
        this._setOption('groupOperations', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies text for a hint that appears when a user pauses on the UI component.
    
     */
    get hint() {
        return this._getOption('hint');
    }
    set hint(value) {
        this._setOption('hint', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * Specifies groups&apos; maximum nesting level.
    
     */
    get maxGroupLevel() {
        return this._getOption('maxGroupLevel');
    }
    set maxGroupLevel(value) {
        this._setOption('maxGroupLevel', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies the number of the element when the Tab key is used for navigating.
    
     */
    get tabIndex() {
        return this._getOption('tabIndex');
    }
    set tabIndex(value) {
        this._setOption('tabIndex', value);
    }
    /**
     * Allows you to specify a filter.
    
     */
    get value() {
        return this._getOption('value');
    }
    set value(value) {
        this._setOption('value', value);
    }
    /**
     * Specifies whether the UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    change(_) { }
    get customOperationsChildren() {
        return this._getOption('customOperations');
    }
    set customOperationsChildren(value) {
        this.setChildren('customOperations', value);
    }
    get fieldsChildren() {
        return this._getOption('fields');
    }
    set fieldsChildren(value) {
        this.setChildren('fields', value);
    }
    _createInstance(element, options) {
        return new DxFilterBuilder(element, options);
    }
    writeValue(value) {
        this.eventHelper.lockedValueChangeEvent = true;
        this.value = value;
        this.eventHelper.lockedValueChangeEvent = false;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    registerOnChange(fn) { this.change = fn; }
    registerOnTouched(fn) { this.touched = fn; }
    _createWidget(element) {
        super._createWidget(element);
        this.instance.on('focusOut', (e) => {
            this.eventHelper.fireNgEvent('onBlur', [e]);
        });
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('customOperations', changes);
        this.setupChanges('fields', changes);
        this.setupChanges('groupOperations', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('customOperations');
        this._idh.doCheck('fields');
        this._idh.doCheck('groupOperations');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxFilterBuilderComponent.ɵfac = function DxFilterBuilderComponent_Factory(t) { return new (t || DxFilterBuilderComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxFilterBuilderComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxFilterBuilderComponent, selectors: [["dx-filter-builder"]], contentQueries: function DxFilterBuilderComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, DxiCustomOperationComponent, 4);
        i0.ɵɵcontentQuery(dirIndex, DxiFieldComponent, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customOperationsChildren = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fieldsChildren = _t);
    } }, hostBindings: function DxFilterBuilderComponent_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("valueChange", function DxFilterBuilderComponent_valueChange_HostBindingHandler($event) { return ctx.change($event); })("onBlur", function DxFilterBuilderComponent_onBlur_HostBindingHandler($event) { return ctx.touched($event); });
    } }, inputs: { accessKey: "accessKey", activeStateEnabled: "activeStateEnabled", allowHierarchicalFields: "allowHierarchicalFields", customOperations: "customOperations", disabled: "disabled", elementAttr: "elementAttr", fields: "fields", filterOperationDescriptions: "filterOperationDescriptions", focusStateEnabled: "focusStateEnabled", groupOperationDescriptions: "groupOperationDescriptions", groupOperations: "groupOperations", height: "height", hint: "hint", hoverStateEnabled: "hoverStateEnabled", maxGroupLevel: "maxGroupLevel", rtlEnabled: "rtlEnabled", tabIndex: "tabIndex", value: "value", visible: "visible", width: "width" }, outputs: { onContentReady: "onContentReady", onDisposing: "onDisposing", onEditorPrepared: "onEditorPrepared", onEditorPreparing: "onEditorPreparing", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onValueChanged: "onValueChanged", accessKeyChange: "accessKeyChange", activeStateEnabledChange: "activeStateEnabledChange", allowHierarchicalFieldsChange: "allowHierarchicalFieldsChange", customOperationsChange: "customOperationsChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", fieldsChange: "fieldsChange", filterOperationDescriptionsChange: "filterOperationDescriptionsChange", focusStateEnabledChange: "focusStateEnabledChange", groupOperationDescriptionsChange: "groupOperationDescriptionsChange", groupOperationsChange: "groupOperationsChange", heightChange: "heightChange", hintChange: "hintChange", hoverStateEnabledChange: "hoverStateEnabledChange", maxGroupLevelChange: "maxGroupLevelChange", rtlEnabledChange: "rtlEnabledChange", tabIndexChange: "tabIndexChange", valueChange: "valueChange", visibleChange: "visibleChange", widthChange: "widthChange", onBlur: "onBlur" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            CUSTOM_VALUE_ACCESSOR_PROVIDER,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxFilterBuilderComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxFilterBuilderComponent, [{
        type: Component,
        args: [{
                selector: 'dx-filter-builder',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    CUSTOM_VALUE_ACCESSOR_PROVIDER,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { accessKey: [{
            type: Input
        }], activeStateEnabled: [{
            type: Input
        }], allowHierarchicalFields: [{
            type: Input
        }], customOperations: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], fields: [{
            type: Input
        }], filterOperationDescriptions: [{
            type: Input
        }], focusStateEnabled: [{
            type: Input
        }], groupOperationDescriptions: [{
            type: Input
        }], groupOperations: [{
            type: Input
        }], height: [{
            type: Input
        }], hint: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], maxGroupLevel: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], tabIndex: [{
            type: Input
        }], value: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], onContentReady: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onEditorPrepared: [{
            type: Output
        }], onEditorPreparing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onValueChanged: [{
            type: Output
        }], accessKeyChange: [{
            type: Output
        }], activeStateEnabledChange: [{
            type: Output
        }], allowHierarchicalFieldsChange: [{
            type: Output
        }], customOperationsChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], fieldsChange: [{
            type: Output
        }], filterOperationDescriptionsChange: [{
            type: Output
        }], focusStateEnabledChange: [{
            type: Output
        }], groupOperationDescriptionsChange: [{
            type: Output
        }], groupOperationsChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hintChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], maxGroupLevelChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], tabIndexChange: [{
            type: Output
        }], valueChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }], onBlur: [{
            type: Output
        }], change: [{
            type: HostListener,
            args: ['valueChange', ['$event']]
        }], touched: [{
            type: HostListener,
            args: ['onBlur', ['$event']]
        }], customOperationsChildren: [{
            type: ContentChildren,
            args: [DxiCustomOperationComponent]
        }], fieldsChildren: [{
            type: ContentChildren,
            args: [DxiFieldComponent]
        }] }); })();
class DxFilterBuilderModule {
}
/** @nocollapse */ DxFilterBuilderModule.ɵfac = function DxFilterBuilderModule_Factory(t) { return new (t || DxFilterBuilderModule)(); };
/** @nocollapse */ DxFilterBuilderModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxFilterBuilderModule });
/** @nocollapse */ DxFilterBuilderModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxiCustomOperationModule,
            DxiFieldModule,
            DxoFormatModule,
            DxoLookupModule,
            DxoFilterOperationDescriptionsModule,
            DxoGroupOperationDescriptionsModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxiCustomOperationModule,
        DxiFieldModule,
        DxoFormatModule,
        DxoLookupModule,
        DxoFilterOperationDescriptionsModule,
        DxoGroupOperationDescriptionsModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxFilterBuilderModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxiCustomOperationModule,
                    DxiFieldModule,
                    DxoFormatModule,
                    DxoLookupModule,
                    DxoFilterOperationDescriptionsModule,
                    DxoGroupOperationDescriptionsModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxFilterBuilderComponent
                ],
                exports: [
                    DxFilterBuilderComponent,
                    DxiCustomOperationModule,
                    DxiFieldModule,
                    DxoFormatModule,
                    DxoLookupModule,
                    DxoFilterOperationDescriptionsModule,
                    DxoGroupOperationDescriptionsModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxFilterBuilderModule, { declarations: [DxFilterBuilderComponent], imports: [DxiCustomOperationModule,
        DxiFieldModule,
        DxoFormatModule,
        DxoLookupModule,
        DxoFilterOperationDescriptionsModule,
        DxoGroupOperationDescriptionsModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxFilterBuilderComponent, DxiCustomOperationModule,
        DxiFieldModule,
        DxoFormatModule,
        DxoLookupModule,
        DxoFilterOperationDescriptionsModule,
        DxoGroupOperationDescriptionsModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxFilterBuilderComponent, DxFilterBuilderModule };

