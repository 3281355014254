import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, ContentChildren, NgModule } from '@angular/core';
import DxResponsiveBox from 'devextreme/ui/responsive_box';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxiColComponent, DxiItemComponent, DxiRowComponent, DxiColModule, DxiItemModule, DxiLocationModule, DxiRowModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * The ResponsiveBox UI component allows you to create an application or a website with a layout adapted to different screen sizes.

 */
class DxResponsiveBoxComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this._createEventEmitters([
            { subscribe: 'contentReady', emit: 'onContentReady' },
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'itemClick', emit: 'onItemClick' },
            { subscribe: 'itemContextMenu', emit: 'onItemContextMenu' },
            { subscribe: 'itemHold', emit: 'onItemHold' },
            { subscribe: 'itemRendered', emit: 'onItemRendered' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { emit: 'colsChange' },
            { emit: 'dataSourceChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'heightChange' },
            { emit: 'hoverStateEnabledChange' },
            { emit: 'itemHoldTimeoutChange' },
            { emit: 'itemsChange' },
            { emit: 'itemTemplateChange' },
            { emit: 'rowsChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'screenByWidthChange' },
            { emit: 'singleColumnScreenChange' },
            { emit: 'visibleChange' },
            { emit: 'widthChange' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * Specifies the collection of columns for the grid used to position layout elements.
    
     */
    get cols() {
        return this._getOption('cols');
    }
    set cols(value) {
        this._setOption('cols', value);
    }
    /**
     * Binds the UI component to data.
    
     */
    get dataSource() {
        return this._getOption('dataSource');
    }
    set dataSource(value) {
        this._setOption('dataSource', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies the UI component&apos;s height.
    
     */
    get height() {
        return this._getOption('height');
    }
    set height(value) {
        this._setOption('height', value);
    }
    /**
     * Specifies whether the UI component changes its state when a user pauses on it.
    
     */
    get hoverStateEnabled() {
        return this._getOption('hoverStateEnabled');
    }
    set hoverStateEnabled(value) {
        this._setOption('hoverStateEnabled', value);
    }
    /**
     * The time period in milliseconds before the onItemHold event is raised.
    
     */
    get itemHoldTimeout() {
        return this._getOption('itemHoldTimeout');
    }
    set itemHoldTimeout(value) {
        this._setOption('itemHoldTimeout', value);
    }
    /**
     * An array of items displayed by the UI component.
    
     */
    get items() {
        return this._getOption('items');
    }
    set items(value) {
        this._setOption('items', value);
    }
    /**
     * Specifies a custom template for items.
    
     */
    get itemTemplate() {
        return this._getOption('itemTemplate');
    }
    set itemTemplate(value) {
        this._setOption('itemTemplate', value);
    }
    /**
     * Specifies the collection of rows for the grid used to position layout elements.
    
     */
    get rows() {
        return this._getOption('rows');
    }
    set rows(value) {
        this._setOption('rows', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies the function returning the size qualifier depending on the screen&apos;s width.
    
     */
    get screenByWidth() {
        return this._getOption('screenByWidth');
    }
    set screenByWidth(value) {
        this._setOption('screenByWidth', value);
    }
    /**
     * Specifies on which screens all layout elements should be arranged in a single column. Accepts a single or several size qualifiers separated by a space.
    
     */
    get singleColumnScreen() {
        return this._getOption('singleColumnScreen');
    }
    set singleColumnScreen(value) {
        this._setOption('singleColumnScreen', value);
    }
    /**
     * Specifies whether the UI component is visible.
    
     */
    get visible() {
        return this._getOption('visible');
    }
    set visible(value) {
        this._setOption('visible', value);
    }
    /**
     * Specifies the UI component&apos;s width.
    
     */
    get width() {
        return this._getOption('width');
    }
    set width(value) {
        this._setOption('width', value);
    }
    get colsChildren() {
        return this._getOption('cols');
    }
    set colsChildren(value) {
        this.setChildren('cols', value);
    }
    get itemsChildren() {
        return this._getOption('items');
    }
    set itemsChildren(value) {
        this.setChildren('items', value);
    }
    get rowsChildren() {
        return this._getOption('rows');
    }
    set rowsChildren(value) {
        this.setChildren('rows', value);
    }
    _createInstance(element, options) {
        return new DxResponsiveBox(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('cols', changes);
        this.setupChanges('dataSource', changes);
        this.setupChanges('items', changes);
        this.setupChanges('rows', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('cols');
        this._idh.doCheck('dataSource');
        this._idh.doCheck('items');
        this._idh.doCheck('rows');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxResponsiveBoxComponent.ɵfac = function DxResponsiveBoxComponent_Factory(t) { return new (t || DxResponsiveBoxComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxResponsiveBoxComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxResponsiveBoxComponent, selectors: [["dx-responsive-box"]], contentQueries: function DxResponsiveBoxComponent_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, DxiColComponent, 4);
        i0.ɵɵcontentQuery(dirIndex, DxiItemComponent, 4);
        i0.ɵɵcontentQuery(dirIndex, DxiRowComponent, 4);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.colsChildren = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemsChildren = _t);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rowsChildren = _t);
    } }, inputs: { cols: "cols", dataSource: "dataSource", disabled: "disabled", elementAttr: "elementAttr", height: "height", hoverStateEnabled: "hoverStateEnabled", itemHoldTimeout: "itemHoldTimeout", items: "items", itemTemplate: "itemTemplate", rows: "rows", rtlEnabled: "rtlEnabled", screenByWidth: "screenByWidth", singleColumnScreen: "singleColumnScreen", visible: "visible", width: "width" }, outputs: { onContentReady: "onContentReady", onDisposing: "onDisposing", onInitialized: "onInitialized", onItemClick: "onItemClick", onItemContextMenu: "onItemContextMenu", onItemHold: "onItemHold", onItemRendered: "onItemRendered", onOptionChanged: "onOptionChanged", colsChange: "colsChange", dataSourceChange: "dataSourceChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", heightChange: "heightChange", hoverStateEnabledChange: "hoverStateEnabledChange", itemHoldTimeoutChange: "itemHoldTimeoutChange", itemsChange: "itemsChange", itemTemplateChange: "itemTemplateChange", rowsChange: "rowsChange", rtlEnabledChange: "rtlEnabledChange", screenByWidthChange: "screenByWidthChange", singleColumnScreenChange: "singleColumnScreenChange", visibleChange: "visibleChange", widthChange: "widthChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxResponsiveBoxComponent_Template(rf, ctx) { }, encapsulation: 2 });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxResponsiveBoxComponent, [{
        type: Component,
        args: [{
                selector: 'dx-responsive-box',
                template: '',
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { cols: [{
            type: Input
        }], dataSource: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], height: [{
            type: Input
        }], hoverStateEnabled: [{
            type: Input
        }], itemHoldTimeout: [{
            type: Input
        }], items: [{
            type: Input
        }], itemTemplate: [{
            type: Input
        }], rows: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], screenByWidth: [{
            type: Input
        }], singleColumnScreen: [{
            type: Input
        }], visible: [{
            type: Input
        }], width: [{
            type: Input
        }], onContentReady: [{
            type: Output
        }], onDisposing: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onItemClick: [{
            type: Output
        }], onItemContextMenu: [{
            type: Output
        }], onItemHold: [{
            type: Output
        }], onItemRendered: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], colsChange: [{
            type: Output
        }], dataSourceChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], heightChange: [{
            type: Output
        }], hoverStateEnabledChange: [{
            type: Output
        }], itemHoldTimeoutChange: [{
            type: Output
        }], itemsChange: [{
            type: Output
        }], itemTemplateChange: [{
            type: Output
        }], rowsChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], screenByWidthChange: [{
            type: Output
        }], singleColumnScreenChange: [{
            type: Output
        }], visibleChange: [{
            type: Output
        }], widthChange: [{
            type: Output
        }], colsChildren: [{
            type: ContentChildren,
            args: [DxiColComponent]
        }], itemsChildren: [{
            type: ContentChildren,
            args: [DxiItemComponent]
        }], rowsChildren: [{
            type: ContentChildren,
            args: [DxiRowComponent]
        }] }); })();
class DxResponsiveBoxModule {
}
/** @nocollapse */ DxResponsiveBoxModule.ɵfac = function DxResponsiveBoxModule_Factory(t) { return new (t || DxResponsiveBoxModule)(); };
/** @nocollapse */ DxResponsiveBoxModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxResponsiveBoxModule });
/** @nocollapse */ DxResponsiveBoxModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxiColModule,
            DxiItemModule,
            DxiLocationModule,
            DxiRowModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxiColModule,
        DxiItemModule,
        DxiLocationModule,
        DxiRowModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxResponsiveBoxModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxiColModule,
                    DxiItemModule,
                    DxiLocationModule,
                    DxiRowModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxResponsiveBoxComponent
                ],
                exports: [
                    DxResponsiveBoxComponent,
                    DxiColModule,
                    DxiItemModule,
                    DxiLocationModule,
                    DxiRowModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxResponsiveBoxModule, { declarations: [DxResponsiveBoxComponent], imports: [DxiColModule,
        DxiItemModule,
        DxiLocationModule,
        DxiRowModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxResponsiveBoxComponent, DxiColModule,
        DxiItemModule,
        DxiLocationModule,
        DxiRowModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxResponsiveBoxComponent, DxResponsiveBoxModule };

