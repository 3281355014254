import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxBullet from 'devextreme/viz/bullet';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxoMarginModule, DxoSizeModule, DxoTooltipModule, DxoBorderModule, DxoFontModule, DxoFormatModule, DxoShadowModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * The Bullet UI component is useful when you need to compare a single measure to a target value. The UI component comprises a horizontal bar indicating the measure and a vertical line indicating the target value.

 */
class DxBulletComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._createEventEmitters([
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'drawn', emit: 'onDrawn' },
            { subscribe: 'exported', emit: 'onExported' },
            { subscribe: 'exporting', emit: 'onExporting' },
            { subscribe: 'fileSaving', emit: 'onFileSaving' },
            { subscribe: 'incidentOccurred', emit: 'onIncidentOccurred' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'tooltipHidden', emit: 'onTooltipHidden' },
            { subscribe: 'tooltipShown', emit: 'onTooltipShown' },
            { emit: 'colorChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'endScaleValueChange' },
            { emit: 'marginChange' },
            { emit: 'pathModifiedChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'showTargetChange' },
            { emit: 'showZeroLevelChange' },
            { emit: 'sizeChange' },
            { emit: 'startScaleValueChange' },
            { emit: 'targetChange' },
            { emit: 'targetColorChange' },
            { emit: 'targetWidthChange' },
            { emit: 'themeChange' },
            { emit: 'tooltipChange' },
            { emit: 'valueChange' }
        ]);
        optionHost.setHost(this);
    }
    /**
     * Specifies a color for the bullet bar.
    
     */
    get color() {
        return this._getOption('color');
    }
    set color(value) {
        this._setOption('color', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Specifies an end value for the invisible scale.
    
     */
    get endScaleValue() {
        return this._getOption('endScaleValue');
    }
    set endScaleValue(value) {
        this._setOption('endScaleValue', value);
    }
    /**
     * Generates space around the UI component.
    
     */
    get margin() {
        return this._getOption('margin');
    }
    set margin(value) {
        this._setOption('margin', value);
    }
    /**
     * Notifies the UI component that it is embedded into an HTML page that uses a tag modifying the path.
    
     */
    get pathModified() {
        return this._getOption('pathModified');
    }
    set pathModified(value) {
        this._setOption('pathModified', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies whether or not to show the target line.
    
     */
    get showTarget() {
        return this._getOption('showTarget');
    }
    set showTarget(value) {
        this._setOption('showTarget', value);
    }
    /**
     * Specifies whether or not to show the line indicating zero on the invisible scale.
    
     */
    get showZeroLevel() {
        return this._getOption('showZeroLevel');
    }
    set showZeroLevel(value) {
        this._setOption('showZeroLevel', value);
    }
    /**
     * Specifies the UI component&apos;s size in pixels.
    
     */
    get size() {
        return this._getOption('size');
    }
    set size(value) {
        this._setOption('size', value);
    }
    /**
     * Specifies a start value for the invisible scale.
    
     */
    get startScaleValue() {
        return this._getOption('startScaleValue');
    }
    set startScaleValue(value) {
        this._setOption('startScaleValue', value);
    }
    /**
     * Specifies the value indicated by the target line.
    
     */
    get target() {
        return this._getOption('target');
    }
    set target(value) {
        this._setOption('target', value);
    }
    /**
     * Specifies a color for both the target and zero level lines.
    
     */
    get targetColor() {
        return this._getOption('targetColor');
    }
    set targetColor(value) {
        this._setOption('targetColor', value);
    }
    /**
     * Specifies the width of the target line.
    
     */
    get targetWidth() {
        return this._getOption('targetWidth');
    }
    set targetWidth(value) {
        this._setOption('targetWidth', value);
    }
    /**
     * Sets the name of the theme the UI component uses.
    
     */
    get theme() {
        return this._getOption('theme');
    }
    set theme(value) {
        this._setOption('theme', value);
    }
    /**
     * Configures the tooltip.
    
     */
    get tooltip() {
        return this._getOption('tooltip');
    }
    set tooltip(value) {
        this._setOption('tooltip', value);
    }
    /**
     * Specifies the primary value indicated by the bullet bar.
    
     */
    get value() {
        return this._getOption('value');
    }
    set value(value) {
        this._setOption('value', value);
    }
    _createInstance(element, options) {
        return new DxBullet(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
}
/** @nocollapse */ DxBulletComponent.ɵfac = function DxBulletComponent_Factory(t) { return new (t || DxBulletComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxBulletComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxBulletComponent, selectors: [["dx-bullet"]], inputs: { color: "color", disabled: "disabled", elementAttr: "elementAttr", endScaleValue: "endScaleValue", margin: "margin", pathModified: "pathModified", rtlEnabled: "rtlEnabled", showTarget: "showTarget", showZeroLevel: "showZeroLevel", size: "size", startScaleValue: "startScaleValue", target: "target", targetColor: "targetColor", targetWidth: "targetWidth", theme: "theme", tooltip: "tooltip", value: "value" }, outputs: { onDisposing: "onDisposing", onDrawn: "onDrawn", onExported: "onExported", onExporting: "onExporting", onFileSaving: "onFileSaving", onIncidentOccurred: "onIncidentOccurred", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onTooltipHidden: "onTooltipHidden", onTooltipShown: "onTooltipShown", colorChange: "colorChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", endScaleValueChange: "endScaleValueChange", marginChange: "marginChange", pathModifiedChange: "pathModifiedChange", rtlEnabledChange: "rtlEnabledChange", showTargetChange: "showTargetChange", showZeroLevelChange: "showZeroLevelChange", sizeChange: "sizeChange", startScaleValueChange: "startScaleValueChange", targetChange: "targetChange", targetColorChange: "targetColorChange", targetWidthChange: "targetWidthChange", themeChange: "themeChange", tooltipChange: "tooltipChange", valueChange: "valueChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost
        ]), i0.ɵɵInheritDefinitionFeature], decls: 0, vars: 0, template: function DxBulletComponent_Template(rf, ctx) { }, styles: ["[_nghost-%COMP%] {  display: block; }"] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxBulletComponent, [{
        type: Component,
        args: [{
                selector: 'dx-bullet',
                template: '',
                styles: [' :host {  display: block; }'],
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { color: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], endScaleValue: [{
            type: Input
        }], margin: [{
            type: Input
        }], pathModified: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], showTarget: [{
            type: Input
        }], showZeroLevel: [{
            type: Input
        }], size: [{
            type: Input
        }], startScaleValue: [{
            type: Input
        }], target: [{
            type: Input
        }], targetColor: [{
            type: Input
        }], targetWidth: [{
            type: Input
        }], theme: [{
            type: Input
        }], tooltip: [{
            type: Input
        }], value: [{
            type: Input
        }], onDisposing: [{
            type: Output
        }], onDrawn: [{
            type: Output
        }], onExported: [{
            type: Output
        }], onExporting: [{
            type: Output
        }], onFileSaving: [{
            type: Output
        }], onIncidentOccurred: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onTooltipHidden: [{
            type: Output
        }], onTooltipShown: [{
            type: Output
        }], colorChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], endScaleValueChange: [{
            type: Output
        }], marginChange: [{
            type: Output
        }], pathModifiedChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], showTargetChange: [{
            type: Output
        }], showZeroLevelChange: [{
            type: Output
        }], sizeChange: [{
            type: Output
        }], startScaleValueChange: [{
            type: Output
        }], targetChange: [{
            type: Output
        }], targetColorChange: [{
            type: Output
        }], targetWidthChange: [{
            type: Output
        }], themeChange: [{
            type: Output
        }], tooltipChange: [{
            type: Output
        }], valueChange: [{
            type: Output
        }] }); })();
class DxBulletModule {
}
/** @nocollapse */ DxBulletModule.ɵfac = function DxBulletModule_Factory(t) { return new (t || DxBulletModule)(); };
/** @nocollapse */ DxBulletModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxBulletModule });
/** @nocollapse */ DxBulletModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxoMarginModule,
            DxoSizeModule,
            DxoTooltipModule,
            DxoBorderModule,
            DxoFontModule,
            DxoFormatModule,
            DxoShadowModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxoMarginModule,
        DxoSizeModule,
        DxoTooltipModule,
        DxoBorderModule,
        DxoFontModule,
        DxoFormatModule,
        DxoShadowModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxBulletModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxoMarginModule,
                    DxoSizeModule,
                    DxoTooltipModule,
                    DxoBorderModule,
                    DxoFontModule,
                    DxoFormatModule,
                    DxoShadowModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxBulletComponent
                ],
                exports: [
                    DxBulletComponent,
                    DxoMarginModule,
                    DxoSizeModule,
                    DxoTooltipModule,
                    DxoBorderModule,
                    DxoFontModule,
                    DxoFormatModule,
                    DxoShadowModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxBulletModule, { declarations: [DxBulletComponent], imports: [DxoMarginModule,
        DxoSizeModule,
        DxoTooltipModule,
        DxoBorderModule,
        DxoFontModule,
        DxoFormatModule,
        DxoShadowModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxBulletComponent, DxoMarginModule,
        DxoSizeModule,
        DxoTooltipModule,
        DxoBorderModule,
        DxoFontModule,
        DxoFormatModule,
        DxoShadowModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxBulletComponent, DxBulletModule };

