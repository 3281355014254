import * as i0 from '@angular/core';
import { PLATFORM_ID, Component, Inject, Input, Output, NgModule } from '@angular/core';
import DxCircularGauge from 'devextreme/viz/circular_gauge';
import * as i1 from 'devextreme-angular/core';
import { DxComponent, DxTemplateHost, WatcherHelper, NestedOptionHost, IterableDifferHelper, DxIntegrationModule, DxTemplateModule } from 'devextreme-angular/core';
import { DxoAnimationModule, DxoExportModule, DxoGeometryModule, DxoLoadingIndicatorModule, DxoFontModule, DxoMarginModule, DxoRangeContainerModule, DxoBackgroundColorModule, DxiRangeModule, DxoColorModule, DxoScaleModule, DxoLabelModule, DxoFormatModule, DxoMinorTickModule, DxoTickModule, DxoSizeModule, DxoSubvalueIndicatorModule, DxoTextModule, DxoTitleModule, DxoSubtitleModule, DxoTooltipModule, DxoBorderModule, DxoShadowModule, DxoValueIndicatorModule } from 'devextreme-angular/ui/nested';
import * as i2 from '@angular/platform-browser';

/**
 * The CircularGauge is a UI component that indicates values on a circular numeric scale.

 */
class DxCircularGaugeComponent extends DxComponent {
    constructor(elementRef, ngZone, templateHost, _watcherHelper, _idh, optionHost, transferState, platformId) {
        super(elementRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
        this._watcherHelper = _watcherHelper;
        this._idh = _idh;
        this._createEventEmitters([
            { subscribe: 'disposing', emit: 'onDisposing' },
            { subscribe: 'drawn', emit: 'onDrawn' },
            { subscribe: 'exported', emit: 'onExported' },
            { subscribe: 'exporting', emit: 'onExporting' },
            { subscribe: 'fileSaving', emit: 'onFileSaving' },
            { subscribe: 'incidentOccurred', emit: 'onIncidentOccurred' },
            { subscribe: 'initialized', emit: 'onInitialized' },
            { subscribe: 'optionChanged', emit: 'onOptionChanged' },
            { subscribe: 'tooltipHidden', emit: 'onTooltipHidden' },
            { subscribe: 'tooltipShown', emit: 'onTooltipShown' },
            { emit: 'animationChange' },
            { emit: 'centerTemplateChange' },
            { emit: 'containerBackgroundColorChange' },
            { emit: 'disabledChange' },
            { emit: 'elementAttrChange' },
            { emit: 'exportChange' },
            { emit: 'geometryChange' },
            { emit: 'loadingIndicatorChange' },
            { emit: 'marginChange' },
            { emit: 'pathModifiedChange' },
            { emit: 'rangeContainerChange' },
            { emit: 'redrawOnResizeChange' },
            { emit: 'rtlEnabledChange' },
            { emit: 'scaleChange' },
            { emit: 'sizeChange' },
            { emit: 'subvalueIndicatorChange' },
            { emit: 'subvaluesChange' },
            { emit: 'themeChange' },
            { emit: 'titleChange' },
            { emit: 'tooltipChange' },
            { emit: 'valueChange' },
            { emit: 'valueIndicatorChange' }
        ]);
        this._idh.setHost(this);
        optionHost.setHost(this);
    }
    /**
     * Specifies animation properties.
    
     */
    get animation() {
        return this._getOption('animation');
    }
    set animation(value) {
        this._setOption('animation', value);
    }
    /**
     * Specifies a custom template for content in the component&apos;s center.
    
     */
    get centerTemplate() {
        return this._getOption('centerTemplate');
    }
    set centerTemplate(value) {
        this._setOption('centerTemplate', value);
    }
    /**
     * Specifies the color of the parent page element.
    
     */
    get containerBackgroundColor() {
        return this._getOption('containerBackgroundColor');
    }
    set containerBackgroundColor(value) {
        this._setOption('containerBackgroundColor', value);
    }
    /**
     * Specifies whether the UI component responds to user interaction.
    
     */
    get disabled() {
        return this._getOption('disabled');
    }
    set disabled(value) {
        this._setOption('disabled', value);
    }
    /**
     * Specifies the global attributes to be attached to the UI component&apos;s container element.
    
     */
    get elementAttr() {
        return this._getOption('elementAttr');
    }
    set elementAttr(value) {
        this._setOption('elementAttr', value);
    }
    /**
     * Configures the exporting and printing features.
    
     */
    get export() {
        return this._getOption('export');
    }
    set export(value) {
        this._setOption('export', value);
    }
    /**
     * Specifies the properties required to set the geometry of the CircularGauge UI component.
    
     */
    get geometry() {
        return this._getOption('geometry');
    }
    set geometry(value) {
        this._setOption('geometry', value);
    }
    /**
     * Configures the loading indicator.
    
     */
    get loadingIndicator() {
        return this._getOption('loadingIndicator');
    }
    set loadingIndicator(value) {
        this._setOption('loadingIndicator', value);
    }
    /**
     * Generates space around the UI component.
    
     */
    get margin() {
        return this._getOption('margin');
    }
    set margin(value) {
        this._setOption('margin', value);
    }
    /**
     * Notifies the UI component that it is embedded into an HTML page that uses a tag modifying the path.
    
     */
    get pathModified() {
        return this._getOption('pathModified');
    }
    set pathModified(value) {
        this._setOption('pathModified', value);
    }
    /**
     * Specifies gauge range container properties.
    
     */
    get rangeContainer() {
        return this._getOption('rangeContainer');
    }
    set rangeContainer(value) {
        this._setOption('rangeContainer', value);
    }
    /**
     * Specifies whether to redraw the UI component when the size of the container changes or a mobile device rotates.
    
     */
    get redrawOnResize() {
        return this._getOption('redrawOnResize');
    }
    set redrawOnResize(value) {
        this._setOption('redrawOnResize', value);
    }
    /**
     * Switches the UI component to a right-to-left representation.
    
     */
    get rtlEnabled() {
        return this._getOption('rtlEnabled');
    }
    set rtlEnabled(value) {
        this._setOption('rtlEnabled', value);
    }
    /**
     * Specifies a gauge&apos;s scale properties.
    
     */
    get scale() {
        return this._getOption('scale');
    }
    set scale(value) {
        this._setOption('scale', value);
    }
    /**
     * Specifies the UI component&apos;s size in pixels.
    
     */
    get size() {
        return this._getOption('size');
    }
    set size(value) {
        this._setOption('size', value);
    }
    /**
     * Specifies the appearance properties of subvalue indicators.
    
     */
    get subvalueIndicator() {
        return this._getOption('subvalueIndicator');
    }
    set subvalueIndicator(value) {
        this._setOption('subvalueIndicator', value);
    }
    /**
     * Specifies a set of subvalues to be designated by the subvalue indicators.
    
     */
    get subvalues() {
        return this._getOption('subvalues');
    }
    set subvalues(value) {
        this._setOption('subvalues', value);
    }
    /**
     * Sets the name of the theme the UI component uses.
    
     */
    get theme() {
        return this._getOption('theme');
    }
    set theme(value) {
        this._setOption('theme', value);
    }
    /**
     * Configures the UI component&apos;s title.
    
     */
    get title() {
        return this._getOption('title');
    }
    set title(value) {
        this._setOption('title', value);
    }
    /**
     * Configures tooltips.
    
     */
    get tooltip() {
        return this._getOption('tooltip');
    }
    set tooltip(value) {
        this._setOption('tooltip', value);
    }
    /**
     * Specifies the main value on a gauge.
    
     */
    get value() {
        return this._getOption('value');
    }
    set value(value) {
        this._setOption('value', value);
    }
    /**
     * Specifies the appearance properties of the value indicator.
    
     */
    get valueIndicator() {
        return this._getOption('valueIndicator');
    }
    set valueIndicator(value) {
        this._setOption('valueIndicator', value);
    }
    _createInstance(element, options) {
        return new DxCircularGauge(element, options);
    }
    ngOnDestroy() {
        this._destroyWidget();
    }
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        this.setupChanges('subvalues', changes);
    }
    setupChanges(prop, changes) {
        if (!(prop in this._optionsToUpdate)) {
            this._idh.setup(prop, changes);
        }
    }
    ngDoCheck() {
        this._idh.doCheck('subvalues');
        this._watcherHelper.checkWatchers();
        super.ngDoCheck();
        super.clearChangedOptions();
    }
    _setOption(name, value) {
        let isSetup = this._idh.setupSingle(name, value);
        let isChanged = this._idh.getChanges(name, value) !== null;
        if (isSetup || isChanged) {
            super._setOption(name, value);
        }
    }
}
/** @nocollapse */ DxCircularGaugeComponent.ɵfac = function DxCircularGaugeComponent_Factory(t) { return new (t || DxCircularGaugeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.DxTemplateHost), i0.ɵɵdirectiveInject(i1.WatcherHelper), i0.ɵɵdirectiveInject(i1.IterableDifferHelper), i0.ɵɵdirectiveInject(i1.NestedOptionHost), i0.ɵɵdirectiveInject(i2.TransferState), i0.ɵɵdirectiveInject(PLATFORM_ID)); };
/** @nocollapse */ DxCircularGaugeComponent.ɵcmp = /** @pureOrBreakMyCode */ i0.ɵɵdefineComponent({ type: DxCircularGaugeComponent, selectors: [["dx-circular-gauge"]], inputs: { animation: "animation", centerTemplate: "centerTemplate", containerBackgroundColor: "containerBackgroundColor", disabled: "disabled", elementAttr: "elementAttr", export: "export", geometry: "geometry", loadingIndicator: "loadingIndicator", margin: "margin", pathModified: "pathModified", rangeContainer: "rangeContainer", redrawOnResize: "redrawOnResize", rtlEnabled: "rtlEnabled", scale: "scale", size: "size", subvalueIndicator: "subvalueIndicator", subvalues: "subvalues", theme: "theme", title: "title", tooltip: "tooltip", value: "value", valueIndicator: "valueIndicator" }, outputs: { onDisposing: "onDisposing", onDrawn: "onDrawn", onExported: "onExported", onExporting: "onExporting", onFileSaving: "onFileSaving", onIncidentOccurred: "onIncidentOccurred", onInitialized: "onInitialized", onOptionChanged: "onOptionChanged", onTooltipHidden: "onTooltipHidden", onTooltipShown: "onTooltipShown", animationChange: "animationChange", centerTemplateChange: "centerTemplateChange", containerBackgroundColorChange: "containerBackgroundColorChange", disabledChange: "disabledChange", elementAttrChange: "elementAttrChange", exportChange: "exportChange", geometryChange: "geometryChange", loadingIndicatorChange: "loadingIndicatorChange", marginChange: "marginChange", pathModifiedChange: "pathModifiedChange", rangeContainerChange: "rangeContainerChange", redrawOnResizeChange: "redrawOnResizeChange", rtlEnabledChange: "rtlEnabledChange", scaleChange: "scaleChange", sizeChange: "sizeChange", subvalueIndicatorChange: "subvalueIndicatorChange", subvaluesChange: "subvaluesChange", themeChange: "themeChange", titleChange: "titleChange", tooltipChange: "tooltipChange", valueChange: "valueChange", valueIndicatorChange: "valueIndicatorChange" }, features: [i0.ɵɵProvidersFeature([
            DxTemplateHost,
            WatcherHelper,
            NestedOptionHost,
            IterableDifferHelper
        ]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature], decls: 0, vars: 0, template: function DxCircularGaugeComponent_Template(rf, ctx) { }, styles: ["[_nghost-%COMP%] {  display: block; }"] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCircularGaugeComponent, [{
        type: Component,
        args: [{
                selector: 'dx-circular-gauge',
                template: '',
                styles: [' :host {  display: block; }'],
                providers: [
                    DxTemplateHost,
                    WatcherHelper,
                    NestedOptionHost,
                    IterableDifferHelper
                ]
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.NgZone }, { type: i1.DxTemplateHost }, { type: i1.WatcherHelper }, { type: i1.IterableDifferHelper }, { type: i1.NestedOptionHost }, { type: i2.TransferState }, { type: undefined, decorators: [{
                type: Inject,
                args: [PLATFORM_ID]
            }] }]; }, { animation: [{
            type: Input
        }], centerTemplate: [{
            type: Input
        }], containerBackgroundColor: [{
            type: Input
        }], disabled: [{
            type: Input
        }], elementAttr: [{
            type: Input
        }], export: [{
            type: Input
        }], geometry: [{
            type: Input
        }], loadingIndicator: [{
            type: Input
        }], margin: [{
            type: Input
        }], pathModified: [{
            type: Input
        }], rangeContainer: [{
            type: Input
        }], redrawOnResize: [{
            type: Input
        }], rtlEnabled: [{
            type: Input
        }], scale: [{
            type: Input
        }], size: [{
            type: Input
        }], subvalueIndicator: [{
            type: Input
        }], subvalues: [{
            type: Input
        }], theme: [{
            type: Input
        }], title: [{
            type: Input
        }], tooltip: [{
            type: Input
        }], value: [{
            type: Input
        }], valueIndicator: [{
            type: Input
        }], onDisposing: [{
            type: Output
        }], onDrawn: [{
            type: Output
        }], onExported: [{
            type: Output
        }], onExporting: [{
            type: Output
        }], onFileSaving: [{
            type: Output
        }], onIncidentOccurred: [{
            type: Output
        }], onInitialized: [{
            type: Output
        }], onOptionChanged: [{
            type: Output
        }], onTooltipHidden: [{
            type: Output
        }], onTooltipShown: [{
            type: Output
        }], animationChange: [{
            type: Output
        }], centerTemplateChange: [{
            type: Output
        }], containerBackgroundColorChange: [{
            type: Output
        }], disabledChange: [{
            type: Output
        }], elementAttrChange: [{
            type: Output
        }], exportChange: [{
            type: Output
        }], geometryChange: [{
            type: Output
        }], loadingIndicatorChange: [{
            type: Output
        }], marginChange: [{
            type: Output
        }], pathModifiedChange: [{
            type: Output
        }], rangeContainerChange: [{
            type: Output
        }], redrawOnResizeChange: [{
            type: Output
        }], rtlEnabledChange: [{
            type: Output
        }], scaleChange: [{
            type: Output
        }], sizeChange: [{
            type: Output
        }], subvalueIndicatorChange: [{
            type: Output
        }], subvaluesChange: [{
            type: Output
        }], themeChange: [{
            type: Output
        }], titleChange: [{
            type: Output
        }], tooltipChange: [{
            type: Output
        }], valueChange: [{
            type: Output
        }], valueIndicatorChange: [{
            type: Output
        }] }); })();
class DxCircularGaugeModule {
}
/** @nocollapse */ DxCircularGaugeModule.ɵfac = function DxCircularGaugeModule_Factory(t) { return new (t || DxCircularGaugeModule)(); };
/** @nocollapse */ DxCircularGaugeModule.ɵmod = /** @pureOrBreakMyCode */ i0.ɵɵdefineNgModule({ type: DxCircularGaugeModule });
/** @nocollapse */ DxCircularGaugeModule.ɵinj = /** @pureOrBreakMyCode */ i0.ɵɵdefineInjector({ imports: [[
            DxoAnimationModule,
            DxoExportModule,
            DxoGeometryModule,
            DxoLoadingIndicatorModule,
            DxoFontModule,
            DxoMarginModule,
            DxoRangeContainerModule,
            DxoBackgroundColorModule,
            DxiRangeModule,
            DxoColorModule,
            DxoScaleModule,
            DxoLabelModule,
            DxoFormatModule,
            DxoMinorTickModule,
            DxoTickModule,
            DxoSizeModule,
            DxoSubvalueIndicatorModule,
            DxoTextModule,
            DxoTitleModule,
            DxoSubtitleModule,
            DxoTooltipModule,
            DxoBorderModule,
            DxoShadowModule,
            DxoValueIndicatorModule,
            DxIntegrationModule,
            DxTemplateModule
        ], DxoAnimationModule,
        DxoExportModule,
        DxoGeometryModule,
        DxoLoadingIndicatorModule,
        DxoFontModule,
        DxoMarginModule,
        DxoRangeContainerModule,
        DxoBackgroundColorModule,
        DxiRangeModule,
        DxoColorModule,
        DxoScaleModule,
        DxoLabelModule,
        DxoFormatModule,
        DxoMinorTickModule,
        DxoTickModule,
        DxoSizeModule,
        DxoSubvalueIndicatorModule,
        DxoTextModule,
        DxoTitleModule,
        DxoSubtitleModule,
        DxoTooltipModule,
        DxoBorderModule,
        DxoShadowModule,
        DxoValueIndicatorModule,
        DxTemplateModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DxCircularGaugeModule, [{
        type: NgModule,
        args: [{
                imports: [
                    DxoAnimationModule,
                    DxoExportModule,
                    DxoGeometryModule,
                    DxoLoadingIndicatorModule,
                    DxoFontModule,
                    DxoMarginModule,
                    DxoRangeContainerModule,
                    DxoBackgroundColorModule,
                    DxiRangeModule,
                    DxoColorModule,
                    DxoScaleModule,
                    DxoLabelModule,
                    DxoFormatModule,
                    DxoMinorTickModule,
                    DxoTickModule,
                    DxoSizeModule,
                    DxoSubvalueIndicatorModule,
                    DxoTextModule,
                    DxoTitleModule,
                    DxoSubtitleModule,
                    DxoTooltipModule,
                    DxoBorderModule,
                    DxoShadowModule,
                    DxoValueIndicatorModule,
                    DxIntegrationModule,
                    DxTemplateModule
                ],
                declarations: [
                    DxCircularGaugeComponent
                ],
                exports: [
                    DxCircularGaugeComponent,
                    DxoAnimationModule,
                    DxoExportModule,
                    DxoGeometryModule,
                    DxoLoadingIndicatorModule,
                    DxoFontModule,
                    DxoMarginModule,
                    DxoRangeContainerModule,
                    DxoBackgroundColorModule,
                    DxiRangeModule,
                    DxoColorModule,
                    DxoScaleModule,
                    DxoLabelModule,
                    DxoFormatModule,
                    DxoMinorTickModule,
                    DxoTickModule,
                    DxoSizeModule,
                    DxoSubvalueIndicatorModule,
                    DxoTextModule,
                    DxoTitleModule,
                    DxoSubtitleModule,
                    DxoTooltipModule,
                    DxoBorderModule,
                    DxoShadowModule,
                    DxoValueIndicatorModule,
                    DxTemplateModule
                ]
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DxCircularGaugeModule, { declarations: [DxCircularGaugeComponent], imports: [DxoAnimationModule,
        DxoExportModule,
        DxoGeometryModule,
        DxoLoadingIndicatorModule,
        DxoFontModule,
        DxoMarginModule,
        DxoRangeContainerModule,
        DxoBackgroundColorModule,
        DxiRangeModule,
        DxoColorModule,
        DxoScaleModule,
        DxoLabelModule,
        DxoFormatModule,
        DxoMinorTickModule,
        DxoTickModule,
        DxoSizeModule,
        DxoSubvalueIndicatorModule,
        DxoTextModule,
        DxoTitleModule,
        DxoSubtitleModule,
        DxoTooltipModule,
        DxoBorderModule,
        DxoShadowModule,
        DxoValueIndicatorModule,
        DxIntegrationModule,
        DxTemplateModule], exports: [DxCircularGaugeComponent, DxoAnimationModule,
        DxoExportModule,
        DxoGeometryModule,
        DxoLoadingIndicatorModule,
        DxoFontModule,
        DxoMarginModule,
        DxoRangeContainerModule,
        DxoBackgroundColorModule,
        DxiRangeModule,
        DxoColorModule,
        DxoScaleModule,
        DxoLabelModule,
        DxoFormatModule,
        DxoMinorTickModule,
        DxoTickModule,
        DxoSizeModule,
        DxoSubvalueIndicatorModule,
        DxoTextModule,
        DxoTitleModule,
        DxoSubtitleModule,
        DxoTooltipModule,
        DxoBorderModule,
        DxoShadowModule,
        DxoValueIndicatorModule,
        DxTemplateModule] }); })();

/**
 * Generated bundle index. Do not edit.
 */

export { DxCircularGaugeComponent, DxCircularGaugeModule };

